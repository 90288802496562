import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Autocomplete,
  Button,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import moment from 'moment';
import React, { memo, useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { basic_set_open_snackbar } from '../../actions/basic';
import { DRAW_BUTTON, TEXT_LIST } from '../../constants';
import { sortDocuments } from '../../constants/services';
import {
  findProcessDrawDetail,
  processAgreed,
  processCancelRequest,
  processCancelRequestConfirm,
  processConfirmed,
  processRequest,
  processRequestConfirm,
  truncateText,
} from '../../constants/utils';
import CommonModal from '../../custom-components/common-modal/common-modal';
import CustomIconConfirm from './customIconConfirm';
import './document.css';
import UploadAndSearchDraw from './uploadAndSearchDraw';
import { duplicateDrawDetailAction } from 'actions/draw';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CustomProcessCancel from './customProcessCancel';
import ListUserConfirmedAndUnconfirm from 'containers/pages/common/listUserConfirmedAndUnconfirm';

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      confirm_dialog: false,
      color_dialog: false,
      canvasSize: {
        width: 0,
        height: 0,
      },
      activeButton: DRAW_BUTTON.LINE,
      openConfirmList: false,
      openDownloadDialog: false,
      imgHover: {},
      openPopper: false,
      anchorEl: null,
      modalConfirmedAndUnconfirmUser: false,
      // imageDataURL:null,
    };
    this.anchorElRef = React.createRef();
  }

  componentDidMount() {
    this.props.onInit();
  }

  handleChangeTab(value) {
    this.setState({ tab: value });
  }

  // getImage(file) {
  //   return new Promise (function (resolved, rejected) {
  //     var i = new Image()
  //     i.onload = function(){
  //       resolved(i)
  //     };
  //     i.src = file
  //   })
  // }
  // processImage = (imageFile) => {
  //   const reader = new FileReader();
  //   reader.onload = async(event) => {
  //     this.setState({
  //       imageDataURL: event.target.result
  //     });
  //     // console.log("data:",event.target.result)
  //     const img = await this.getImage(event.target.result);
  //     // console.log("=========type:", img)
  //   };
  //  reader.readAsDataURL(imageFile);
  // };

  // handlePaste = e => {
  //   if (e.clipboardData.files.length) {
  //       const fileObject = e.clipboardData.files[0];
  //       const file = {
  //           getRawFile: () => fileObject,
  //           name: fileObject.name,
  //           size: fileObject.size,
  //           uid: guid(),
  //           status: 2,
  //           progress: 0
  //       };

  //       const filesState = this.state.files.map(f => ({ ...f }));
  //       filesState.push(file);

  //       this.setState({ files: filesState });
  //   } else {
  //       alert('No image data was found in your clipboard. Copy an image first or take a screenshot.');
  //   }
  // };

  onPaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    let newFiles = []
    for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1 || items[i].type.indexOf('pdf') !== -1) {
            const imageFile = items[i].getAsFile();
            newFiles.push(imageFile)
            // this.processImage(imageFile);
        }
    }
    if(newFiles.length > 0){
      this.props.onDropFiles(newFiles)
    } 
  };

  render() {
    const {
      document_filter,
      document_list,
      downloadImages,
      property_sidebar,
      handleSearchDrawList,
      onDropFiles,
      document_draw,
      room_select,
      onSelectRoomForSendMessageConfirm,
    } = this.props;
    if (!document_filter) return;
    const {room_by_user} = document_draw;
    const arraySplitDraw = window.location.pathname.split('/');
    const drawId = arraySplitDraw[arraySplitDraw.length - 1];
    const { view } = document_filter;
    const cg_tooltip_elm = document.getElementById("cg-tooltip-disable");
    
    return (
      <>
        <div className="text-center paste-container" id="documents"       
        >
          <div className="views p-2 cg-view" style={{maxHeight:`${cg_tooltip_elm ? `calc(100vh - ${cg_tooltip_elm.offsetHeight + 54+45+45+48}px)`:'initial'}`}}>
            {/* {process.env.REACT_APP_CURR_ENV !== "production" &&
            <Autocomplete
              size='small'
              autoHighlight
              options={room_by_user || []}
              sx={{padding: '0 10px 10px'}}
              getOptionLabel={(option) => option?.property?.variety_house?.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{...params.InputProps, sx: { fontSize: '0.9rem' } }}
                  InputLabelProps={{ sx: { fontSize: '0.9rem' } }}
                  label="チャットルーム名選択"
                />
              )}
              onChange={(_, value) => onSelectRoomForSendMessageConfirm({value, draw_id: drawId})}
              // defaultValue={() => {
              //   const valueDefault = (room_select || []).find((item) => item.draw_id === drawId);
              //   return valueDefault ? valueDefault : null
              // }}
              value={room_select?.id ? room_select : null}
            />} */}
            <UploadAndSearchDraw
              onDropFiles={onDropFiles}
              handleSearchDrawList={handleSearchDrawList}
              isMultiple={true}
            />
            <div
              className={`dropzone-absolute ${
                !property_sidebar?.dragFile ? 'd-none' : ''
              }`}
            >
              <div className={`dropzone-wrapper d-inline-block`}>
                <div className="dropzone-item">
                  <BackupOutlinedIcon className="icon-dropzone" />
                  <p className="mb-1">ここにファイルを</p>
                  <p className="mb-1">ドラッグアンドドロップ</p>
                </div>
              </div>
            </div>
            {view === 'list' ? this.showViewList() : this.showViewGrid()}
          </div>
        </div>
        <CommonModal
          open={this.state.openDownloadDialog}
          onClose={() => {
            this.setState({ ...this.state, openDownloadDialog: false });
          }}
          text={TEXT_LIST.download_images}
          handleCancel={() => {
            this.setState({ ...this.state, openDownloadDialog: false });
          }}
          handleConfirm={() => {
            downloadImages(
              this.state.imgHover?.id ? [this.state.imgHover?.id] : []
            );
            this.setState({ ...this.state, openDownloadDialog: false });
          }}
        />
      </>
    );
  }

  /*
  Show view list
  */
  showViewList() {
    const { document_filter, onChangeInfo } = this.props;
    if (!document_filter) return;
    const document_list = this.sortDocumentByDate();

    let html = [];
    for (const item of document_list) {
      const { date, images } = item;
      html.push(
        <div className={date} key={date}>
          <div className="date">{date}</div>
          <ImageList cols={1} className="view-list">
            {images.map((image, key) => (
              <ImageListItem key={key}>
                <img
                  onClick={() => onChangeInfo(image)}
                  src={`${
                    image.base64 +
                    (!image.base64 ? "?'fit=crop&auto=format'" : '')
                  }`}
                  srcSet={`${
                    image.base64 +
                    (!image.base64 ? "?'fit=crop&auto=format&dpr=2 2x'" : '')
                  }`}
                  alt={image.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  sx={{ height: '100%' }}
                  title={image.name}
                  // subtitle={<span>by: {item.author}</span>}
                  // position="right"
                  // actionPosition ='right'
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      );
    }
    return <div className="view-list">{html}</div>;
  }

  /*
  Show view grid
  */
  showViewGrid() {
    const {
      document_filter,
      document_info,
      onChangeInfo,
      onChangeRemove,
      onChangeRemoveList,
      onDeselectListItem,
      clearConfirm,
      document_draw,
      openPreviewDialog,
      me,
      selectImage,
      selectedImageIds,
      document_list: originDocumentList,
    } = this.props;
    if (!document_filter) return;
    let html = [];
    const document_list = this.sortDocumentByDate();

    const id = this.state.anchorEl ? 'transition-popper' : undefined;
    for (const document of document_list) {
      const { date, images } = document;

      const sortedImages = sortDocuments(images, me.id);

      html.push(
        <div className={date} key={date}>
          <div className="date">{date}</div>
          <ImageList cols={3}  rowHeight={90}>
            {sortedImages.map((image, key) => {
              const { process, file_same_name, file_same_name_have_ex, attachment, user_confirm_draws } = image;
              const {url, url_resize} = attachment ?? {};
              const urlImage = url_resize ?? url;
              const processDrawDetail = findProcessDrawDetail(process);
              let title = processDrawDetail ? processDrawDetail.title : null;
              if(user_confirm_draws?.find(({to_confirmed}) => !to_confirmed)) {
                if(process === processRequestConfirm.type|| process === processConfirmed.type) {
                  title = '確認中';
                } else if(process === processRequest.type || process === processAgreed.type) {
                  title = '記入中';
                }
              }
              if (image.from_confirmed && !image.to_confirmed && (process === processCancelRequest.type || process === processCancelRequestConfirm.type)) {
                title = '差戻し';
              }
              
              return (
                <Tooltip placement="top" arrow title={this.state.imgHover?.id === image.id && file_same_name} key={key}>
                  <ImageListItem
                    key={key}
                    className={`${document_info.id === image.id && !document_info.parent_id ? 'draw-active' : ''} ${document_info.parent_id && document_info.parent_id === image.id ? 'draw-active' : ''}`}
                    sx={{
                      cursor: 'pointer',
                    }}
                    onMouseOver={(e) => {
                      this.setState({ ...this.state, imgHover: image });
                    }}
                    // onMouseLeave={() => {
                    //   this.setState({ ...this.state, imgHover: {} });
                    // }}
                    onClick={() => {
                      onChangeInfo(document_info, image);
                    }}
                  >
                    {this.state.imgHover?.id === image.id && (
                      <div>
                        {!selectedImageIds.includes(image.id) && (
                          <input
                            type="checkbox"
                            style={{
                              position: 'absolute',
                              left: '2px',
                              cursor: 'pointer',
                              zIndex: 1,
                              height: '18px',
                              width: '18px',
                              // backgroundColor: '#0e3359',
                              // WebkitBoxShadow: '0 0 0px 1000px #102e50 inset',
                              outline: '1px solid #0e3359',
                              outlineOffset: '-1px',
                            }}
                            onChange={(e) => {
                              selectImage(image.id, e.target.checked);
                            }}
                            checked={selectedImageIds.includes(image.id)}
                          />
                        )}

                        <Stack
                          ref={this.anchorElRef}
                          aria-describedby={id}
                          type="button"
                          style={{
                            position: 'absolute',
                            right: '2px',
                            backgroundColor: '#0e3359',
                            cursor: 'pointer',
                            zIndex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(0,0,0,0.3)',
                            opacity: 0.6,
                            borderRadius: '2px',
                            height: '18px',
                            width: '18px',
                          }}
                          onClick={(e) => {
                            this.setState({
                              ...this.state,
                              anchorEl: this.anchorElRef.current,
                            });
                          }}
                        >
                          <MoreHorizIcon
                            className="color-white"
                            fontSize="small"
                          />
                        </Stack>
                        <RenderMenuPoper
                          open={!!this.state.anchorEl}
                          anchorEl={this.state.anchorEl}
                          handleClose={() => {
                            this.setState({
                              ...this.state,
                              anchorEl: null,
                            });
                          }}
                          fileName={this.state.imgHover?.file_same_name_have_ex}
                          handleDownload={() =>
                            this.setState({
                              ...this.state,
                              openDownloadDialog: true,
                              anchorEl: null,
                            })
                          }
                          handleDelete={() => onChangeRemove(image)}
                          disabledDelete={
                            !(me && me.id === image.owner_id)
                          }
                          drawDetailId={document_info?.id}
                          setAnchorEl={() => this.setState({anchorEl: null})}
                          modalConfirmedAndUnconfirmUser={this.state.modalConfirmedAndUnconfirmUser}
                          setModalConfirmedAndUnconfirmUser={() => this.setState({modalConfirmedAndUnconfirmUser: !this.state.modalConfirmedAndUnconfirmUser})}
                          process={process}
                          user_confirm_draws={document_info?.user_confirm_draws ?? []}
                        />
                      </div>
                    )}
                    {selectedImageIds.includes(image.id) && (
                      <input
                        type="checkbox"
                        style={{
                          position: 'absolute',
                          left: '2px',
                          backgroundColor: '#2196f3',
                          cursor: 'pointer',
                          zIndex: 1,
                          height: '18px',
                          width: '18px',
                        }}
                        onChange={(e) => {
                          selectImage(image.id, e.target.checked);
                        }}
                        checked={selectedImageIds.includes(image.id)}
                      />
                    )}
                    <img
                      // onMouseOver={() => {
                      //   this.setState({ ...this.state, imgHover: image });
                      // }}
                      src={`${
                        urlImage +
                        (!urlImage ? "?'fit=crop&auto=format'" : '')
                      }`}
                      srcSet={`${
                        urlImage +
                        (!urlImage
                          ? "?'fit=crop&auto=format&dpr=2 2x'"
                          : '')
                      }`}
                      alt={image.title}
                      loading="lazy"
                    />
                    {process ? (
                      <span>
                        <ImageListItemBar
                          title={title}
                          position="top"
                          sx={{
                            background: processDrawDetail.badge ? processDrawDetail.badge : processDrawDetail.color,
                            cursor: 'pointer',
                            border:
                              process === processAgreed.type ||
                              process === processConfirmed.type
                                ? '2px solid black'
                                : 'none',
                          }}
                          onClick={() => onChangeInfo(document_info, image)}
                        />
                        {(process === processAgreed.type ||
                          process === processConfirmed.type) &&
                          user_confirm_draws?.every(({to_confirmed}) => to_confirmed) && (
                          <CustomIconConfirm />
                        )}
                        {process === processCancelRequest.type && (
                          <CustomProcessCancel isWarningRed={true} />
                        )}
                         {process === processCancelRequestConfirm.type && (
                          <CustomProcessCancel isWarningRed={true} />
                        )}
                      </span>
                    ) : null}
                  </ImageListItem>
                </Tooltip>
              );
            })}
          </ImageList>
        </div>
      );
    }
    return (
      <div className="view-grid">
        {html}
        {document_list.length > 0 && (
          <>
            {selectedImageIds.length > 0 && (
              <Stack
                direction="row"
                py={1}
                // spacing={2}
                sx={{
                  backgroundColor: '#4ea3f8',
                  justifyContent: 'space-between',
                  borderRadius: '8px',
                  marginTop: '10px',
                }}
                onMouseOver={() =>
                  this.setState({ ...this.state, imgHover: {} })
                }
              >
                <Button
                  className="d-flex flex-column btn-secondary "
                  onClick={() => {
                    this.setState({ ...this.state, openDownloadDialog: true });
                  }}
                >
                  <FileDownloadOutlinedIcon />
                  <span className="button_invite_label">
                    {TEXT_LIST.download}
                  </span>
                </Button>

                <Button
                  disabled={isDeleteListItem(
                    selectedImageIds,
                    originDocumentList,
                    me
                  )}
                  className={`d-flex flex-column btn-secondary ${
                    isDeleteListItem(selectedImageIds, originDocumentList, me)
                      ? 'btn-disable'
                      : ''
                  }`}
                  onClick={onChangeRemoveList}
                >
                   <i className="fa-regular fa-trash-can" style={{
                      padding: '5px',
                      fontSize: '18px',
                    }}></i>
                  <span className="button_invite_label">削除</span>
                </Button>

                <Button
                  className="d-flex flex-column btn-secondary"
                  onClick={onDeselectListItem}
                >
                  <CloseRoundedIcon />
                  <span className="button_invite_label">選択解除</span>
                </Button>
              </Stack>
            )}
          </>
        )}
         <ListUserConfirmedAndUnconfirm
            modalConfirmedAndUnconfirmUser={this.state.modalConfirmedAndUnconfirmUser}
            setModalConfirmedAndUnconfirmUser={() => this.setState({modalConfirmedAndUnconfirmUser: !this.state.modalConfirmedAndUnconfirmUser})}
            document_info={document_info}
          />
      </div>
    );
  }

  sortDocumentByDate() {
    const { document_list } = this.props;
    let sortDocument = [];
    for (const document of document_list) {
      const date = moment(document.date).format('YYYY/MM/DD');
      const index = sortDocument.findIndex((s) => s.date === date);
      if (index > -1) {
        sortDocument[index].images.push(document);
      } else {
        sortDocument.push({ date: date, images: [document] });
      }
    }
    return sortDocument;
  }
}

export const QRDialog = memo(({ url, forceReloadCoppy, listUser }) => {
  const [isCoppied, setIsCoppied] = useState(false);
  const { info: draw_info } = useSelector((a) => a.draw);

  useEffect(() => {
    setIsCoppied(false);
  }, [forceReloadCoppy]);

  const dispatch = useDispatch();

  const listUserName = useMemo(() => {
    let res = "";
    for (let i = 0; i < listUser.length; i++) {
      res = res + `${listUser[i]?.user_address_book?.user_name ?? listUser[i]?.user?.name}様`
      if(i < listUser.length - 1) {
        res = res + `、`;
      }
    }
    return res;
  }, [listUser]);
  return (
    <div className="h-100">
      <Typography
        id="modal-modal-title"
        variant="h5"
        component="h2"
        fontWeight="700"
        textAlign="center"
        marginY={'50px'}
      >
        依頼確認
      </Typography>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className='d-flex'>
          <Typography >{<span>{!!listUser?.length ? listUserName : draw_info?.endUserName}{!!listUser?.length ? 'に' : '様に'}</span>}依頼を送信しました。</Typography>
        </div>
        <div className="d-flex justify-content-center my-5">
          <div>
            <Typography>確認や記入は以下のURLから可能です。</Typography>
            <Typography>
              なお、以下のURLはお客様にメールにて送信されています。
            </Typography>
            <Typography>
              また、以下のQRコードからもアクセス可能です。
            </Typography>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Typography textAlign={'center'}>{url}</Typography>
          <div
            className="btn-modal ms-3 d-inline-block"
            onClick={() => {
              navigator.clipboard.writeText(url);
              setIsCoppied(true);
              dispatch(
                basic_set_open_snackbar({
                  t: 'success',
                  m: 'コピーできました',
                })
              );
            }}
          >
            {!isCoppied ? 'コピー' : 'コピーされました'}
          </div>
        </div>
        <div
          style={{
            height: 'auto',
            margin: '0 auto',
            maxWidth: 150,
            width: '100%',
            marginTop: 75,
          }}
        >
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={url}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
    </div>
  );
});

export const RenderMenuPoper = memo(
  ({
    anchorEl,
    setAnchorEl,
    open,
    handleClose,
    fileName,
    handleDownload,
    handleDelete,
    disabledDelete,
    drawDetailId,
    setModalConfirmedAndUnconfirmUser,
    process,
    user_confirm_draws,
  }) => {
    const dispatch = useDispatch();
    const subDrawList = useSelector(state => state?.draw?.sub_draw);
    const me = useSelector(state => state?.basic?.me);
    let isWarning = true;
    if(process === processRequest.type) {
      isWarning = true;
    } else if(process === processRequestConfirm.type) {
      isWarning = false;
    }
    return (
      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: '#264362',
            color: 'white',
          },
        }}
        getcontentanchorel={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={setAnchorEl}
      >
        {fileName && <MenuItem>{truncateText(fileName)}</MenuItem>}
        {
          !!user_confirm_draws?.length &&
          <MenuItem onClick={setModalConfirmedAndUnconfirmUser}>
            {isWarning ? '記入状況' : '確認状況'}
          </MenuItem>
        }
        <MenuItem onClick={handleDownload}>
          <FileDownloadOutlinedIcon className='mr-1' />
          {TEXT_LIST.download}
        </MenuItem>
        {
          me && 
          <>
            <MenuItem onClick={() => dispatch(duplicateDrawDetailAction({drawDetailId, duplicateAll: false, subDrawList}))}>
              <ContentCopyIcon className='mr-1' />
              元ファイルの複製
            </MenuItem>
            <MenuItem onClick={() => dispatch(duplicateDrawDetailAction({drawDetailId, duplicateAll: true, subDrawList}))}>
              <FileCopyIcon className='mr-1' />
              編集後ファイル複製
            </MenuItem>
          </>
        }
        
        <MenuItem disabled={disabledDelete} onClick={handleDelete}>
          <i className="fa-regular fa-trash-can mr-1" style={{
            padding: '0 5px',
            fontSize: '18px',
          }}></i>
          削除
        </MenuItem>
      </Menu>
    );
  }
);

export const isDeleteListItem = (selectedImageIds, document_list, me) => {
  const item = document_list
    .filter((item) => selectedImageIds.includes(item?.id))
    .find((item) => item?.process || item?.owner_id !== me.id);
  return !!item;
};

export default Documents;
