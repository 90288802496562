import React, { useState, useEffect } from 'react';
import {
  Box,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Button,
  Tooltip,
  IconButton,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

import {
  processRequestConfirm,
  processCancelRequest,
  processCancelRequestConfirm,
} from 'constants/utils';
import moment from 'moment';
import IconWarningRed from '../../../assets/images/warning_red.png';
import IconWarningYellow from '../../../assets/images/warning_yellow.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: 'auto',
};

const ListUserConfirmedAndUnconfirm = ({
  modalConfirmedAndUnconfirmUser,
  setModalConfirmedAndUnconfirmUser,
  document_info,
}) => {
  const { user_confirm_draws } = document_info ?? {};
  const [listUser, setListUser] = useState(user_confirm_draws);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('processedAt'); // 初期値は処理日時でソート

  useEffect(() => {
    setListUser(user_confirm_draws ?? []);
  }, [document_info]);

  // ソートハンドラ
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // ソート処理
  const sortListUser = (array, comparator) => {
    if (!array || array.length === 0) return [];
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedArray.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === 'processedAt') {
      // 処理日時のソート
      const aDate = new Date(a.processedAt);
      const bDate = new Date(b.processedAt);
      return aDate - bDate;
    } else if (orderBy === 'status') {
      // ステータスのソート
      const statusOrder = { 済: 1, 未確認: 2, 未記入: 3, 差戻し: 4 };
      const aStatus = getStatus(a);
      const bStatus = getStatus(b);
      return statusOrder[aStatus] - statusOrder[bStatus];
    }
    return 0;
  };

  const getStatus = (item) => {
    if (
      item.process === processCancelRequest.type ||
      item.process === processCancelRequestConfirm.type
    ) {
      return '差戻し';
    }
    return item.to_confirmed ? '済' : item.process === processRequestConfirm.type ? '未確認' : '未記入';
  };

  const sortedListUser = sortListUser(listUser, getComparator(order, orderBy));

  return (
    <Modal
      open={modalConfirmedAndUnconfirmUser}
      onClose={setModalConfirmedAndUnconfirmUser}
    >
      <Stack sx={{ ...style, position: 'relative' }}>
        {/* 右上のXボタン */}
        <IconButton
          onClick={setModalConfirmedAndUnconfirmUser}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>

        {/* 検索フィールド */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box display="flex" alignItems="center">
            <p className="mr-2">依頼先:</p>
            <TextField
              onChange={(e) => {
                const data = user_confirm_draws.filter((item) => {
                  const { user, user_address_book } = item ?? {};
                  return (
                    user?.email.includes(e.target.value) ||
                    user?.name.includes(e.target.value) ||
                    user_address_book?.user_name.includes(e.target.value)
                  );
                });
                setListUser(e.target.value ? data : user_confirm_draws);
              }}
              size="small"
              label="検索"
              variant="outlined"
            />
          </Box>
        </Box>

        {/* テーブルの表示 */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell>メールアドレス</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? order : 'asc'}
                  onClick={() => handleRequestSort('status')}
                  IconComponent={() => {
                    const iconStyle = {
                      opacity: orderBy === 'status' ? 1 : 0.3,
                      fontSize: '18px',
                    };
                    return (
                      <span style={{ visibility: 'visible', marginTop: '4px' }}>
                        {orderBy === 'status' ? (
                          order === 'desc' ? (
                            <ArrowDownward style={iconStyle} />
                          ) : (
                            <ArrowUpward style={iconStyle} />
                          )
                        ) : (
                          <ArrowDownward style={iconStyle} />
                        )}
                      </span>
                    );
                  }}
                >
                  ステータス
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'processedAt'}
                  direction={orderBy === 'processedAt' ? order : 'asc'}
                  onClick={() => handleRequestSort('processedAt')}
                  IconComponent={() => {
                    const iconStyle = {
                      opacity: orderBy === 'processedAt' ? 1 : 0.3,
                      fontSize: '18px',
                    };
                    return (
                      <span style={{ visibility: 'visible', marginTop: '4px' }}>
                        {orderBy === 'processedAt' ? (
                          order === 'desc' ? (
                            <ArrowDownward style={iconStyle} />
                          ) : (
                            <ArrowUpward style={iconStyle} />
                          )
                        ) : (
                          <ArrowDownward style={iconStyle} />
                        )}
                      </span>
                    );
                  }}
                >
                  処理日時
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedListUser.map((item, index) => {
              const {
                user,
                user_address_book,
                processedAt,
                complain,
                process,
              } = item ?? {};
              const userName = user_address_book?.user_name ?? user?.name;
              const status = getStatus(item);
              const isWarningRed = process === processCancelRequest.type;

              return (
                <TableRow key={index}>
                  <TableCell>
                    <Tooltip title={userName} arrow>
                      <Box display="flex" alignItems="center">
                        <Box
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                            maxWidth: '120px',
                          }}
                        >
                          {userName}
                        </Box>
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={user?.email} arrow>
                      <Box
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '120px',
                        }}
                      >
                        {user?.email}
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {status === '差戻し' ? (
                      <>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <Chip
                              label={status}
                              color="error"
                              variant="outlined"
                            />
                          </Box>
                          <IconButton size="small">
                            <img
                              src={
                                isWarningRed
                                  ? IconWarningRed
                                  : IconWarningYellow
                              }
                              alt="warning"
                              width={20}
                            />
                          </IconButton>
                        </Box>
                        <Box sx={{ mt: 1, color: 'black' }}>
                          {complain || 'コメントなし'}
                        </Box>
                      </>
                    ) : (
                      <>
                        {status === '未記入' || status === '未確認' ? (
                          <Box>
                            <Chip
                              label={status}
                              color="default"
                              variant="outlined"
                            />
                          </Box>
                        ) : (
                          <Box>
                            <Chip
                              label={status}
                              color="success"
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: '120px',
                    }}
                  >
                    {processedAt
                      ? moment(new Date(Date.parse(processedAt))).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : ''}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {/* 下の真ん中に閉じるボタン */}
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="contained"
            onClick={setModalConfirmedAndUnconfirmUser}
          >
            閉じる
          </Button>
        </Box>
      </Stack>
    </Modal>
  );
};

export default React.memo(ListUserConfirmedAndUnconfirm);
