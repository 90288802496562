export const SIGN_IN_USER_BY_COOKIE = `
  mutation SignInByCookie {
    signInByCookie {
      id
      name
      company {
        id
        company_type
      }
      token
    }
  }
`;

export const REGISTER_DRAW = `
  mutation registerDraw($mode: String!, $drawInput: DrawInput!) {
    registerDraw(mode: $mode, drawInput: $drawInput ) {
      id
      name
      user_name
      createdAt
      updatedAt
    }
  }
`;
export const REMOVE_DRAW = `
  mutation removeDraw($id: String!) {
    removeDraw(id: $id) {
      id
      name
    }
  }
`;

export const ASSIGN_USER_TO_DRAW = `
  mutation assignUserToDraw($userDrawInput: UserDrawInput!) {
    assignUserToDraw(userDrawInput: $userDrawInput ) {
      id
      name
      user_name
      user_address_book_id
      url_confirm
      createdAt
      updatedAt
    }
  }
`;

// Upload lines's json of every image to server, image to S3
export const UPLOAD_DRAW_DETAILS = `
  mutation uploadDrawDetails($drawId: String!, $details: [JSON]) {
    uploadDrawDetails(drawId: $drawId, details: $details) {
      id
      details {
        id
        draw_id
        from_confirmed
        to_confirmed
        attachment {
          id
          file_name
          file_type
          url
          file_alias
        }
        elements
        process
        processedAt
        owner_id
        createdAt
        user_confirm_draws{
          id
          to_confirmed
          process
          processedAt
          user{
            id
            avatar
            name
            email
          }
          user_address_book{
            id
            user_name
          }
        }
      }
    }
  }
`;

export const UPLOAD_DRAW_DETAILS_BY_USER = `
  mutation uploadDrawDetailsByUser($drawId: String!, $details: [JSON]) {
    uploadDrawDetailsByUser(drawId: $drawId, details: $details)
  }
`;

export const DUPLICATE_DRAW_DETAIL = `
  mutation duplicateDrawDetail($drawDetailId: String!, $duplicateAll: Boolean, $subDrawList: [JSON]) {
    duplicateDrawDetail(drawDetailId: $drawDetailId, duplicateAll: $duplicateAll, subDrawList: $subDrawList)
  }
`;


export const SAVE_DRAW_DETAIL = `
  mutation saveDrawDetail($detail: JSON!) {
    saveDrawDetail(detail: $detail){
      id
      details {
        id
        draw_id
        from_confirmed
        to_confirmed
        attachment {
          id
          file_name
          file_type
          url
          file_alias
        }
        elements
        process
        processedAt
        owner_id
        createdAt
      }
    }
  }
`;

export const SAVE_DRAW_DETAIL_BY_USER = `
  mutation saveDrawDetailByUser($detail: JSON!) {
    saveDrawDetailByUser(detail: $detail)
  }
`;

export const CLEAR_CONFIRM = `
    mutation clearConfirm($drawId: String!) {
      clearConfirm (drawId: $drawId)
    }
`;

export const REMOVE_DRAW_DETAIL = `
    mutation removeDrawDetail($drawDetailId: String!) {
      removeDrawDetail(drawDetailId: $drawDetailId) {
        id
      }
    }
`;

export const REMOVE_DRAW_DETAIL_BY_USER = `
    mutation removeDrawDetailByUser($drawDetailId: String!) {
      removeDrawDetailByUser(drawDetailId: $drawDetailId) {
        id
      }
    }
`;

export const SEND_PASSWORD_DRAW = `
  mutation SendPasswordDraw($draw_id: String!, $user_group_chat_id: ID) {
    sendPasswordDraw(draw_id: $draw_id, user_group_chat_id: $user_group_chat_id )
  }
`;

export const SIGN_IN_DRAW = `
  mutation SignInDraw($email: String!, $temporary_pwd: String!, $finalTel: String, $draw_id: ID) {
    signInDraw(email: $email, temporary_pwd: $temporary_pwd, finalTel: $finalTel, draw_id: $draw_id) {
      id
      token
      expiresIn
    }
  }
`;

export const SEND_CONFIRM_DRAW = `
  mutation sendConfirmDraw($drawId: String!, $isSendMail: Boolean) {
    sendConfirmDraw(drawId: $drawId,isSendMail: $isSendMail)
  }
`;


export const CONFIRM_DRAW_DETAIL_BY_USER = `
  mutation confirmDrawDetailByUser($confirmedItem: JSON!, $subDrawList : [JSON], $process: String, $complain: String, $file_same_name_have_ex: String) {
    confirmDrawDetailByUser(confirmedItem: $confirmedItem, subDrawList: $subDrawList, process: $process, complain: $complain, file_same_name_have_ex: $file_same_name_have_ex)
  }
`;

export const CHANGE_MAIL_DRAW_IN_USER_ADDRESS_BOOK = `
  mutation changeMailDrawInUserAddressBook($id: ID!, $email: String!) {
    changeMailDrawInUserAddressBook(id: $id, email: $email )
  }
`;

export const ADD_MESSAGE_INTO_CHAT = `
  mutation addMessageFromDraw ($to_user_id: ID!, $room_id: ID!, $message: String!, $attachment_id: ID, $copied: Boolean, $schedule_document_id: String, $confirm_document_id: String, $property_id: ID,$line_seller_receive_notif: Boolean,$send_message_to_mail: Boolean, $stored: Boolean, $message_to_user: [JSON]) {
    addMessageFromDraw(to_user_id: $to_user_id, room_id: $room_id, message: $message, attachment_id: $attachment_id, copied: $copied, schedule_document_id: $schedule_document_id, confirm_document_id: $confirm_document_id, property_id: $property_id,line_seller_receive_notif: $line_seller_receive_notif,send_message_to_mail: $send_message_to_mail, stored: $stored, message_to_user: $message_to_user){
      id
    }
  }
`;

export const CREATE_DRAW_SCHEDULE = `
  mutation createDrawSchedule ($id: ID, $draw_id: ID, $room_id: ID, $content: String, $from_user_id: String, $to_user_id: String, $start_time: String, $end_time: String) {
    createDrawSchedule(id: $id, draw_id: $draw_id, room_id: $room_id, content: $content, from_user_id: $from_user_id, to_user_id: $to_user_id, start_time: $start_time, end_time: $end_time)
  }
`

export const UPDATE_STATUS_DRAW_SCHEDULE = `
  mutation updateStatusDrawSchedule ($id: ID!, $status: Boolean, $room_id: ID, $finish_user_id: ID) {
    updateStatusDrawSchedule(id: $id, status: $status, room_id: $room_id, finish_user_id: $finish_user_id)
  }
`
export const DELETE_DRAW_SCHEDULE = `
  mutation deleteDrawSchedule ($id: ID!, $room_id: ID) {
    deleteDrawSchedule(id: $id, room_id: $room_id)
  }
`

export const CONVERT_BASE64_FILE = `
mutation convertBase64pdftoImage ($base64: String) {
  convertBase64pdftoImage(base64: $base64){
    base64
    pageNumber
  }
}
`

export const DRAW_REGISTER_USER_3D = `
mutation drawRegisterUser($email: String!,$full_name: String,$url: String,$host_id: String, $id: String, $draw_id: String) {
  drawRegisterUser(email: $email,full_name: $full_name,url: $url,host_id: $host_id,id: $id,draw_id: $draw_id){
    id
  }
}
`

export const UPDATE_CG_3D_URL = `
mutation updateCg3DUrl($property_id: String!,$id: String!,$u_id: String!) {
  updateCg3DUrl(property_id: $property_id,id: $id,u_id :$u_id)
}
`
export const SIGN_IN_CLIENT_WITH_DRAW = `
  mutation signInClientWithDraw($email: String!, $u_id: String!,$id: String!) {
    signInClientWithDraw(email: $email, u_id: $u_id,id: $id){
      token
    }
  }
`;

export const REGISTER_USER_PROPERTY_3D = `
  mutation RegisterUserProperty ($userPropertyInput: UserPropertyInput!) {
    registerUserProperty(userPropertyInput: $userPropertyInput){
      id
    }
  }
`;

export const UPDATE_AGREED_USER_PROPERTY = `
  mutation UpdateAgreedUserProperty ($id: String!, $material_id: String!) {
    updateAgreedUserProperty(id: $id, material_id: $material_id)
  }
`;

export const REGISTER_PROPERTY_LIST_3D = `
  mutation registerPropertyListForUser ($userPropertyInput: [UserPropertyInput],$comboId: String,$mode: String) {
    registerPropertyListForUser(userPropertyInput: $userPropertyInput,comboId : $comboId,mode: $mode)
  }
`;

export const UPDATE_AGREED_LIST = `
  mutation updateListPropertyAgreed ($isSave: Boolean, $listMaterial: [listMaterialId]) {
    updateListPropertyAgreed(isSave: $isSave, listMaterial: $listMaterial)
  }
`;

export const SEND_SMS_DRAW = `
  mutation sendSMSDraw ($draw_id: ID!, $tel: String!, $method: Int) {
    sendSMSDraw(draw_id: $draw_id, tel: $tel, method: $method)
  }
`

export const ADD_NEW_RECORD_3D = `
  mutation addNewRecord ($record_name: String!,$property_id: String!,$materialInput: [MaterialsInput], $draw_id: String, $extensionInput: [JSON]) {
    addNewRecord(record_name: $record_name,property_id : $property_id,materialInput: $materialInput, draw_id: $draw_id, extensionInput: $extensionInput){
      id
      is_save
      record_name
      user_id
      property_id
      material_data
      draw_id
      extension_data
    }
  }
`;
export const UPDATE_RECORD_3D = `
  mutation updateRecord ($id: String!, $record_name: String!,$property_id: String!,$materialInput: [MaterialsInput],$extensionInput: [JSON]) {
    updateRecord(id: $id, record_name: $record_name,property_id : $property_id,materialInput: $materialInput, extensionInput: $extensionInput){
      id
      is_save
      record_name
      user_id
      property_id
      material_data
      draw_id
      extension_data
    }
  }
`;
export const UPDATE_RECORD_NAME_3D = `
  mutation updateRecordName ($record_name: String!,$id: String!) {
    updateRecordName(record_name: $record_name,id : $id){
      id
      is_save
      record_name
      user_id
      property_id
      material_data
      draw_id
      extension_data
    }
  }
`;

export const UPDATE_RECORD_SAVE_3D = `
  mutation updateRecordSave ($isSave: Boolean!,$id: String!) {
    updateRecordSave(isSave: $isSave,id : $id){
      id
      is_save
      record_name
      user_id
      property_id
      material_data
      draw_id
      extension_data
    }
  }
`;

export const ADD_NEW_FRAME_3D = `
  mutation addNewFrame ($draw_id: String!,$frames_data: FrameInput) {
    addNewFrame(draw_id: $draw_id,frames_data: $frames_data)
  }
`;

export const REMOVE_FRAME_3D = `
  mutation removeFrame ($draw_id: String!,$id: String!) {
    removeFrame(draw_id: $draw_id,id: $id)
  }
`;

export const ADD_ATTACHMENT_DRAW = `
mutation addAttachmentDraw($attachmentInput: AttachmentInput) {
  addAttachmentDraw(attachmentInput: $attachmentInput) {
    id
    url
    file_password
    file_name
  }
}
`;

export const UPDATE_FRAME_INDEX = `
  mutation updateFrameIndex ($draw_id: String!,$index: String!,$old_index: String!,$frame_id: String!) {
    updateFrameIndex(draw_id: $draw_id,index: $index, old_index: $old_index, frame_id: $frame_id)
  }
`;

export const UPDATE_TEXT_CONFIRM_POSITION = `
  mutation updateTextPosition ($draw_detail_id: String!,$position: String!) {
    updateTextPosition(draw_detail_id: $draw_detail_id,position: $position)
  }
`;

export const DELETE_CG_DRAW = `
  mutation removeCG ($draw_id: String!,$id: String!) {
    removeCG(draw_id: $draw_id,id: $id)
  }
`;

export const DELETE_CG_3D = `
  mutation deleteUserPropertiesData($property_id: String!) {
    deleteUserPropertiesData(property_id: $property_id)
  }
`;

export const UPDATE_USER_CONFIRM_DRAW = `
  mutation updateUserConfirmDraw($drawDetailId: ID, $listUser: [JSON]) {
    updateUserConfirmDraw(drawDetailId: $drawDetailId, listUser: $listUser)
  }
`;

export const REGISTER_ACCOUNT_USER_BY_SHARE_ID_GENERAL = `
  mutation registerAccountUserByShareIdGeneral($share_id: ID, $tab_id: ID, $email: String!, $user_name: String!, $isSendOTP: Boolean, $phone_number: String, $method: String, $isHaveLine: Boolean, $room_id: String) {
    registerAccountUserByShareIdGeneral(share_id: $share_id, tab_id: $tab_id,  email: $email, user_name: $user_name, isSendOTP: $isSendOTP, phone_number: $phone_number, method: $method, isHaveLine: $isHaveLine, room_id: $room_id)
  }
`;