import React from 'react';
import { COOKIE_EXPIRES_IN, HOST_ADDR, HOST_ADDR_3D } from './setting';
import { GraphQLClient } from 'graphql-request';
import { SIGN_IN_USER_BY_COOKIE } from '../share/mutation';
import moment from 'moment';
import { basic_loading, basic_set_open_snackbar } from '../actions/basic';
import * as PDFJS from 'pdfjs-dist';
import { DRAW_DETAIL_PROCESS, TEXT_LIST } from './index';
import history from '../containers/pages/history';
import Swal from 'sweetalert2';
import { handleUserDropFiles } from 'ccontainers/cpages/user/draw/cdraw.components';
import { handleDropFiles } from 'ccontainers/common/cdocument';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import * as GraphQLWs from "graphql-ws";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { WS_HOST_ADDR } from "./setting";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { swalFire } from './swal';

PDFJS.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + '/pdf.worker.js';

const cMapUrl = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.3.200/cmaps/';
const MAX_SIZE_UPLOAD = process.env.REACT_APP_MAX_SIZE_UPLOAD || 1;
const name_token = 'x-token';
const name_cookie = 'mina-toku-sess';
const name_draw = 'x-token';

const responseMiddleware = (response) => {
  if(response?.response?.errors?.[0]?.message === 'Context creation failed: unauthorized') {
    removeCookie(name_token, COOKIE_DOMAIN);
    return window.location.reload();
  }
}

export const createClient3D = () => {
  const client = new GraphQLClient(HOST_ADDR_3D +'/graphql', {
    headers: {
      "sp-client-token": localStorage.getItem("sp-client-token"),
      "sp-type": 'client'
    },
  });

  return client;
};

export const createClient = () => {
  const client = new GraphQLClient(HOST_ADDR+'/graphql', {
    credentials: 'include', // include cookies
    headers: {
      'x-token': getCookieXToken(),
      'user-type': 'seller',
      // Used for localhost
      'dev-cookie': document.cookie,
    },
    responseMiddleware,

  });

  return client;
};
export const createClientDraw = () => {
  return new GraphQLClient(HOST_ADDR+'/graphql', {
    credentials: 'include', // include cookies
    headers: {
      'x-token': getCookie(name_draw),
      'user-type': 'general',
    },
    responseMiddleware,
  });
};

export const requestGraphQL = async (requestString, params) => {
  const client = createClient();
  return await client.request(requestString, params);
};

export const userRequestGraphQL = async (requestString, params) => {
  const client = createClientDraw();
  return await client.request(requestString, params);
};

/**
 * sign in cookie
 * @returns {Promise.<boolean>}
 */
export const signInByCookie = async () => {
  try {
    const client = createClient();
    await client.request(SIGN_IN_USER_BY_COOKIE, {}).then((data) => {
      const { signInByCookie } = data;
      if (signInByCookie) {
        setCookieXToken(signInByCookie.token, signInByCookie.company.id);
      }
    });
  } catch (e) {
    console.log('No user found');
    return redirectToLoginPage();
  }

  return true;
};

export const getCookieXToken = () => {
  return getCookie(name_token);
};

export const getCookieID = () => {
  return getCookie(name_cookie);
};

export const getCookie = (cname) => {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

// set cookie x-token
export const setCookieXToken = (token, company_id) => {
  const cookie_domain = process.env.REACT_APP_COOKIE_DOMAIN;
  setCookie(name_token, token, COOKIE_EXPIRES_IN, cookie_domain);

  localStorage.setItem('isLoggedIn', 'true');
  if (company_id) localStorage.setItem('company_id', company_id);

  return true;
};

//Set cookie
export const setCookie = (cname, cvalue, exdays, domain) => {
  console.log({ cname, cvalue, exdays });
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  if(domain) {
    document.cookie =  cname + "=" + cvalue + ";" + expires + ";domain=" +  domain + ";path=/";
  } else {
    document.cookie =  cname + "=" + cvalue + ";" + expires + ";path=/";
  }
};

// Clear all cookies
export const removeAllCookie = () => {
  document.cookie.split(';').forEach(function (c) {
    removeCookie(c, COOKIE_DOMAIN);
  });
};

// Clear cookies
// export const removeCookie = (cname) => {
//   document.cookie = cname + '=; Max-Age=0; path=/; domain=';
// };
export const removeCookie = (cname, domain) => {
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=";
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=" + domain;
};

// Get error message from network result
export const getErrorMessage = (error) => {
  console.error(error);
  let error_message = 'エラーが発生しました。もう一度お試しください';
  if (error && error.response && error.response.errors[0].message) {
    error_message = error.response.errors[0].message;
  }

  error_message = error_message.replace(/Can't edit/, TEXT_LIST.cannot_edit);
  error_message = error_message.replace(
    /Invalid password/,
    TEXT_LIST.invalid_password
  );

  return error_message;
};

export const redirectToLoginPage = () => {
  // Clear all localStorage
  localStorage.clear();
  // Clear all cookies
  console.log(document.cookie);
  removeCookie(name_token, COOKIE_DOMAIN);
  removeCookie(name_cookie, COOKIE_DOMAIN);
  // document.cookie.split(';').forEach(function (c) {
  //   removeCookie(c);
  // });
  // Redirect to page login
  return (window.location.href = process.env.REACT_APP_PCHAT_LOGIN_URL);
};

/**
 * quyennguyen
 */

export const sizeImageMax = () => {
  return parseInt(MAX_SIZE_UPLOAD) * 1048576;
};

export const checkSizeImage = (size, dispatch) => {
  if (parseInt(size) > sizeImageMax()) {
    dispatch(
      basic_set_open_snackbar({
        m: `添付可能なファイルサイズは${MAX_SIZE_UPLOAD}MB以下です`,
        v: 'error',
      })
    );
    return false;
  }
  return true;
};

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      resolve({
        base64: reader.result,
        name: file.name,
        size: file.size,
        type: file.type,
        date: moment(),
      });
    };
    reader.onerror = (error) => reject(error);
  });
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}
function getImage(file) {
  return new Promise (function (resolved, rejected) {
    var i = new Image()
    i.onload = function(){
      resolved(i)
    };
    i.src = file
  })
}

export const convertBase64PdfToImage = async (data,scale) => {
  console.log("scale:",scale)
  const images = [];
  const pdf = await PDFJS.getDocument({
    url: data,
    cMapUrl,
    cMapPacked: true,
  }).promise;
  const canvas = document.createElement('canvas');
  // if(pdf.numPages > 10){
  //   scale = 2
  // }
  for (let i = 0; i < pdf.numPages; i++) {
    console.log("convert file :",i)
    const page = await pdf.getPage(i + 1);

    const viewport = page.getViewport({ scale: scale });
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    images.push(canvas.toDataURL("image/jpeg", 0.5));
  }
  // canvas.remove();

  // const imgMerge = await mergeImage(
  //   images.map((item) => item.split('data:image/png;base64,')[1]),
  //   { direction: true }
  // );
  console.log("====== client success => server uploading...")
  return {
    base64: images,
    pageNumber: pdf.numPages,
  };
};

export const COLOR_TAGS = [
  '#000',
  '#62bf4e',
  '#f2d700',
  '#f9a01b',
  '#f55845',
  '#c378e1',
  '#3dc2e0',
  '#207ac0',
  '#51e999',
  '#f675b3',
  '#b3bac6',
  '#334463',
  // 'transparent',
];

export const validateEmail = (email) => {
  if (!email || email.trim === '') return false;
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
};

export function toDataURL(imgUrl, callback) {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    ctx.drawImage(image, 0, 0);
    const dataUrl = canvas.toDataURL();
    callback && callback(dataUrl);
  };
  image.src = imgUrl;
}

// Project draw

export const findProcessDrawDetail = (type) => {
  return DRAW_DETAIL_PROCESS.find((d) => d.type === type);
};
export const findTypeProcessDrawDetail = (type) => {
  const findType = DRAW_DETAIL_PROCESS.find((d) => d.type === type);
  return findType ? findType.type : '';
};
export const findTitleProcessDrawDetail = (type) => {
  const findType = DRAW_DETAIL_PROCESS.find((d) => d.type === type);
  return findType ? findType.title : '';
};

export const processRequest = findProcessDrawDetail('request');
export const processRequestConfirm = findProcessDrawDetail('request-confirm');
export const processAgreed = findProcessDrawDetail('agreed');
export const processConfirmed = findProcessDrawDetail('confirmed');
export const processCancelRequest = findProcessDrawDetail('cancelRequest');
export const processCancelRequestConfirm = findProcessDrawDetail('cancelRequestConfirm');

export const resetRoute = (route) => {
  history.entries = [];
  history.index = -1;
  history.push(route);
};

export const uniqueArray = (array) => {
  return array.filter((x, i, a) => a.indexOf(x) == i);
};

export const FILE_TYPE = {
  pdf: 'data:application/pdf;base64,',
  png: 'data:image/png;base64,',
};

export const removeExtension = (filename) => {
  return filename.substring(0, filename.lastIndexOf('.')) || filename;
};

export const getExtension = (filename) => {
  return filename.substring(filename.lastIndexOf('.'), filename?.length) || filename;
};

export const convertSameFileName = (document_list) => {
  const listFile = [...document_list].reverse();
  const fileCount = {};
  const newFileNames = listFile.map((item) => {
    const { file_alias } = item.attachment;
    if (!file_alias) return { ...item };
    const aliasNonEx = removeExtension(file_alias);
    if (aliasNonEx in fileCount) {
      ++fileCount[aliasNonEx];
      return {
        ...item,
        file_same_name: `${aliasNonEx}(${fileCount[aliasNonEx]})`,
        file_same_name_have_ex: `${aliasNonEx}(${fileCount[aliasNonEx]})${getExtension(file_alias)}`,
      };
    } else {
      fileCount[aliasNonEx] = 0;
      return { ...item, file_same_name: aliasNonEx, file_same_name_have_ex: file_alias };
    }
  });
  return newFileNames.reverse();
};

export const clientUrl = window.location.protocol + '//' + window.location.host;

export const truncateText = (str, num = 20) => {
  if(str.length <= num) return str;
  return str.slice(0, num) + '...';
}

export const checkLoginWithOtherGuest = async () => {
  const result = await Swal.fire({
    icon: 'question',
    title: '<h5>アクセス権限がありません。ログアウトしますか？</h5>',
    showCancelButton: true,
    confirmButtonText: 'はい',
    cancelButtonText: 'いいえ',
    width: 590,
  });
  return result.value;
};

export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

export const getDrawIdFromUrlOfDrawDetail = () => {
  const arraySplitDraw = window.location.pathname.split('/');
  return arraySplitDraw[arraySplitDraw.length - 1];
}

export const compareDate = (time) => {
  const date = moment(time).format('yyyy/MM/dd');
  const now = moment().format('yyyy/MM/dd');
  return date === now;
};

export const hideTelNumber = (tel) => {
  if(!tel) return;
  return tel.replace(/.(?=.{4})/g, '*');
}

export const removeHyphenPhoneNumber = (tel) => {
  if(!tel) return;
  return tel.replaceAll("-", "");
}

export const hideMail = (email) => {
  if(!email) return;
  const [name, domain] = email.split("@");
  // const replaced = name.slice(0, name.length - 4) + '****@' + domain;
  let dot = '';
  for (let i = 0; i < name.length - 4; i++) {
    dot += '*';
  }
  const res = dot + name.slice(-4) + '@' + domain;
  return res;
}

export const isShowTel = (tel) => {
  if(!tel || !tel.trim() || (tel === '000-0000-0000')) return false;
  return true;
}

export const validatePhoneNumber = (phoneNumber) => {
  const regex = /^[0-9\-]{9,13}$/;
  return regex.test(phoneNumber);
}

export const createOulook = ({
  item,
  start_time,
  end_time,
  title,
  content,
  location,
  isAllDay = true,
}) => {
  const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
  const startDateTime = start_time ? moment(start_time).format(dateFormat) : moment(end_time).format(dateFormat);;
  const endDateTime = moment(end_time).add(1, 'days').format(dateFormat);
  const url = `https://outlook.office.com/calendar/action/compose?subject=${encodeURIComponent(title ?? "")}&startdt=${startDateTime}&enddt=${endDateTime}&body=${encodeURIComponent(content ?? "")}&location=${encodeURIComponent(location ?? "")}&allday=${isAllDay}`;
  return (
    <a 
      href={url} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      {item}
    </a>
  );
};

export const createGoogleCalendar = ({
  item,
  start_time,
  end_time,
  title,
  content,
  isAllDay = true,
}) => {
  const dateFormat = isAllDay ? 'YYYYMMDD' : 'YYYYMMDDTHHMMSSZ';
  const startDateTime = start_time ? moment(start_time).format(dateFormat) : moment(end_time).format(dateFormat);
  const endDateTime = moment(end_time).format(dateFormat);
  return (
    <a
      href={`http://www.google.com/calendar/event?action=TEMPLATE&dates=${startDateTime + "/" + endDateTime}&text=${title || ""}&details=${content || ""}`}
      className="google-calendar"
      target="_blank"
      rel="noopener noreferrer"
    >
      {item}
    </a>
  );
};

export const onPasteFile = (event,dispatch,isHost = false) => {
  const clipboardData = event.clipboardData || window.clipboardData;
  const items = clipboardData.items;
  let newFiles = []
  for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1 || items[i].type.indexOf('pdf') !== -1) {
          const imageFile = items[i].getAsFile();
          newFiles.push(imageFile)
      }
  }
  if(newFiles.length > 0){
    // this.props.onDropFiles(newFiles)
    if(isHost){
      handleDropFiles(newFiles,dispatch,3)
    }else{
      handleUserDropFiles(newFiles,dispatch,3)
    }
    
  } 
};

// singleton pattern
class Subscription {
  static instance;

  constructor() {
    if(Subscription.instance) return Subscription.instance;

    if (process.env.NODE_ENV !== "production") {  // Adds messages only in a dev environment
      loadDevMessages();
      loadErrorMessages();
    }

    const link = new GraphQLWsLink(GraphQLWs.createClient({
      url: `${WS_HOST_ADDR}/graphql`,
      connectionParams: {
        authToken: getCookieXToken()
      },
      shouldRetry: () => {
        return true;
      },
      retryAttempts: Infinity,
      on: {
        closed: () => {
          console.log('server closed');
        },
        connected: () => {
          console.log("server started");
        }
      },
    }));

    const client = new ApolloClient({
      link,
      cache: new InMemoryCache()
    });

    Subscription.instance = client
    return Subscription.instance
  }
}

export const getUserGroupChatId = (url) => {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname;

  const segments = pathname.split('/');

  const userGroupIndex = segments.findIndex(segment => segment === 'user_group');

  const userGroupChatId = segments[userGroupIndex + 1];
  return userGroupChatId
}
export const extractNumbersFromUUID = (uuid)=> {
  const parts = uuid.split('-');
    const lastPart = parts[4];
    return parseFloat(`0.${lastPart.match(/\d+/g).join('')}`);
}

export const subscription = new Subscription();