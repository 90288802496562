import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { getListDrawSchedule, getListUserBelongDraw } from 'actions/schedule';
import Schedule from 'containers/pages/schedule';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EXPANDED_CONST, TAB_DRAW_SCHEDULE, WIDTH_MOBILE } from '../../../constants';
import '../cg-modal.css';

const AccordionSchedule = ({ expanded, setexpanded, draw_id }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state?.basic?.me);
  const draw_info = useSelector((state) => state?.draw?.info);
  const document_draw = useSelector((state) => state?.document?.info);
  const statusListDrawSchedule = useSelector(
    (state) => state?.schedule?.status_list_draw_schedule
  );
  const { role, isShowTaskFinish } = statusListDrawSchedule ?? {};
  const isMobile = useMediaQuery(`(max-width:${WIDTH_MOBILE}px)`);
  return (
    <Accordion
      expanded={EXPANDED_CONST.LIST_SCHEDULE_DRAW === expanded}
      className={`cg-bar-ac`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() => {
          setexpanded(
            expanded === EXPANDED_CONST.LIST_SCHEDULE_DRAW
              ? ''
              : EXPANDED_CONST.LIST_SCHEDULE_DRAW
          );
          dispatch(
            getListDrawSchedule({
              draw_id,
              role: role,
              isShowTaskFinish,
            })
          );
          dispatch(getListUserBelongDraw({ draw_id }));
        }}
        className="cg-item"
      >
        <Typography className="ac-parent-title">
          {isMobile ? 'やる事' : 'やる事・期限'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="cg-details bg-white">
        {me ? (
          <Schedule
            draw_id={draw_info?.id}
            room_id={draw_info?.room_id}
            roleUser={TAB_DRAW_SCHEDULE.HOST}
          />
        ) : (
          <Schedule
            draw_id={draw_info?.id}
            room_id={draw_info?.room_id}
            roleUser={me?.id}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
export default React.memo(AccordionSchedule);
