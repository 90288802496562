import {
  CallReceivedRounded,
  CheckRounded,
  CircleOutlined,
  CloseRounded,
  CropDinOutlined,
  ModeEditOutlineRounded,
} from '@mui/icons-material';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NearMeIcon from '@mui/icons-material/NearMe';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Input,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import UploadAndSearchDraw from 'containers/common/uploadAndSearchDraw';
import moment from 'moment';
import React, { PureComponent, memo, useMemo, useRef, useState,useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  document_set_dialog,
  document_set_info,
  saveDocumentInfoCanvasAction,
} from '../../../../actions/document';
import {
  saveDrawDetailsByUserAction,
  saveDrawDetailsNavigation,
  sub_draw_set_info,
} from '../../../../actions/draw';
import {
  changeElementKonvaAction,
  changeStepKonvaAction,
  initKonvaAction
} from '../../../../actions/konva-canvas';
import { CDocument } from '../../../../ccontainers/cpages/user/draw/cdraw.components';
import { ROOM_TYPE, TAB_DRAW_SCHEDULE, TEXT_LIST, WIDTH_MOBILE, WIDTH_TABLET } from '../../../../constants';
import { sortDocuments } from '../../../../constants/services';
import { KONVA_CHANGE, KONVA_CHANGE_TOOL } from '../../../../constants/store';
import {
  COLOR_TAGS,
  createClientDraw,
  findProcessDrawDetail,
  processAgreed,
  processCancelRequest,
  processCancelRequestConfirm,
  processConfirmed,
  processRequest,
  processRequestConfirm,
  truncateText,
  typeOfRoom,
} from '../../../../constants/utils';
import { ImagePreview } from '../../../../custom-components/KonvaCanvas/Konva.components';
import KonvaCanvas from '../../../../custom-components/KonvaCanvas/KonvaCanvas';
import CommonModal from '../../../../custom-components/common-modal/common-modal';
import ProcessLabel from '../../../../custom-components/process-label/process-label';
import { TOOL } from '../../../../reducers/konva-canvas/konva-canvas';
import './draw.css';
import CustomIconConfirm from 'containers/common/customIconConfirm';
import { RenderMenuPoper, isDeleteListItem } from 'containers/common/document';
import Schedule from 'containers/pages/schedule';
import { GET_SUB_DRAW, GET_USER_ADDRESS_BOOK } from 'share/query';
import { swalQuestion } from 'constants/swal';
import CombineImageIntoPdf from 'custom-components/CombineImageIntoPdf';
import { PCHAT_DOMAIN } from 'constants/setting';
import SidebarRight from 'custom-components/sidebarRight';
import CG3DForm from 'custom-components/CG3DForm/CG3DForm';
import CustomProcessCancel from 'containers/common/customProcessCancel';
import MobileHeaderMenu from 'custom-components/mobile-header-menu/mobile-header-menu';
import { isMobile } from 'react-device-detect';
import IconWarningRed from '../../../../assets/images/warning_red.png';
import IconWarningYellow from '../../../../assets/images/warning_yellow.png';
import CloseIcon from '@mui/icons-material/Close';

let change = false

// DOCUMENT DIALOG
export const DocumentContent = memo(() => {
  const {
    list: document_list,
    info: document_info,
    dialog: document_dialog,
  } = useSelector((a) => a.document);
  const { info: draw_info } = useSelector((a) => a.draw);
  const { me_draw, windowSize } = useSelector((a) => a.basic);
  const isMobile = useMediaQuery(`(max-width:${WIDTH_MOBILE}px)`);
  const isTablet = useMediaQuery(`(max-width:${WIDTH_TABLET}px)`);
  const popupStrokeRef = useRef(null);
  const popupColorRef = useRef(null);

  const color =
    document_info && document_info.color ? document_info.color : '#000';

  const fontSize =
    document_info && document_info?.fontSize ? document_info.fontSize : 16;

  const processing = findProcessDrawDetail(document_info.process);
  const {
    element,
    history,
    step,
    stageScale: document_scale,
    tool,
    isChanged
  } = useSelector((a) => a.konvaCanvas);

  const {sub_draw} = useSelector((a)=> a.draw)

  const elements = useMemo(
    () => history[step]?.elements ?? [],
    [history, step]
  );
  const [footerHeight, setfooterHeight] = useState(70);
  const [headerHeight, setheaderHeight] = useState(70);
  const [colorDialog, setColorDialog] = useState(false);
  const [strokeDialog, setStrokeDialog] = useState(false);
  const [limitZoomIn, setLimitZoomIn] = useState(false);
  const [endUser, setendUser] = useState('');
  const [onChangeKonVa, setonChangeKonVa] = useState(false);
  const [pageInfoIndex, setpageInfoIndex] = useState(0);
  const [sizeCanvas, setSizeCanvas] = useState({
    width:0,
    height:0
  });
  const [isShowNavbar, setShowNavbar] = useState(true);
  const [isDialogComplain, setDialogComplain] = useState(false);
  const [complainContent, setComplainContent] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const scrollRef = useRef();
  const dispatch = useDispatch();
  
  const getUserAddressBook= async (id)=>{
    let userName ;
    const client = createClientDraw()
    await client.request(GET_USER_ADDRESS_BOOK, {id: id}).then((data) => {
      userName = data?.getUserAddressBookById?.user_name || "";
    })
    setendUser(userName)
    return userName
  }
  const handleSetKonvaChange = (data)=>{
    if(!change){
      setonChangeKonVa(data)
      change = true
    }
  }

  const pen_active = useMemo(() => tool === TOOL.PEN, [tool]);
  const arrow_active = useMemo(() => tool === TOOL.ARROW, [tool]);
  const straight_line_active = useMemo(() => tool === TOOL.STRAIGHT_LINE, [tool]);
  const check_active = useMemo(() => tool === TOOL.CHECK, [tool]);
  const drag_active = useMemo(() => tool === TOOL.DRAG, [tool]);
  const text_active = useMemo(() => tool === TOOL.TEXT, [tool]);
  const square_active = useMemo(() => tool === TOOL.SQUARE, [tool]);
  const circle_active = useMemo(() => tool === TOOL.CIRCLE, [tool]);
  const editable = useMemo(() => !document_info.process, [document_info]);


  const sortDocumentByDate = () => {
    let sortDocument = [];
    for (const document of document_list) {
      const date = moment(document.date).format('YYYY/MM/DD');
      const index = sortDocument.findIndex((s) => s.date === date);
      if (index > -1) {
        sortDocument[index].images.push(document);
      } else {
        sortDocument.push({ date: date, images: [document] });
      }
    }
    const result = sortDocument.map((sortByDate) => {
      return sortDocuments(sortByDate.images, me_draw.id);
    });

    return result.flat();
  };
  // let infoIndex = sortDocumentByDate().findIndex(
  //   (item) => item.id === document_info.id
  // );

  useEffect(() => {
    if(document_list.length && !document_info.parent_id){
      const firstPageIndex = sortDocumentByDate().findIndex(
        (item) => item.id === document_info.id
      );
      setpageInfoIndex(firstPageIndex)
    }
    
  }, [document_list,document_info])
  


  const onChangeTool = (tool) => {
    dispatch({
      type: KONVA_CHANGE_TOOL,
      payload: tool,
    });
  };

  const onChangeValue = (name, value) => {
    document_info[name] = value;
    dispatch(document_set_info(document_info));

    if (name === 'color' && element && element.key > -1) {
      const findElement = elements.find((ele) => ele.key === element.key);
      if (element) {
        dispatch(
          changeElementKonvaAction({
            key: element.key,
            element: {
              ...findElement,
              color: value,
            },
            newStep: true,
          })
        );
      }
    }

    if (name === 'fontSize' && element && element.key > -1) {
      const findElement = elements.find((ele) => ele.key === element.key);
      if (element) {
        dispatch(
          changeElementKonvaAction({
            key: element.key,
            element: {
              ...findElement,
              fontSize: value,
            },
            newStep: true,
          })
        );
      }
    }
  };
 

  const  myEventHandler =  useCallback(
    async(event)=>{
      if(event.key === "ArrowLeft" || event.key === 'ArrowRight'){
        if(change){
          await swalQuestion({
            html: 'この画像は編集中で、保存されていません。他の画像を開くと編集した内容を保存しますか？',
            confirmButtonText: '保存する',
            cancelButtonText: '保存しないで移動する',
          }).then(result => {
            if (result.isConfirmed) {
                dispatch(saveDrawDetailsNavigation(document_info.id, 'save'))
            }
            change = false;
          });
        }
      let currentPage ;
        if(event.key === "ArrowLeft"){
          currentPage = pageInfoIndex - 1 >= 0 ? pageInfoIndex - 1 : 0 ;
          // console.log("page 1:",infoIndex)
          
        }else if(event.key === 'ArrowRight'){
          const maxLength = document_list.length -1;
          currentPage = pageInfoIndex + 1 <= maxLength ? pageInfoIndex + 1 : maxLength ;
        }
        const data = sortDocumentByDate()
        await onChangeInfo(data[currentPage])
        const client = createClientDraw()
        await client.request(GET_SUB_DRAW, {draw_parent_id: data[currentPage].id}).then((subInfo) => {
          let subData = subInfo.getSubDraws
          if(subData.length > 0){
            subData.unshift(data[currentPage])
          } 
          dispatch(sub_draw_set_info(subData));
        })
        setpageInfoIndex(currentPage)
        // infoIndex = currentPage
      }
      // onChangeTool(tool)
     
    },
    [document_list,document_info]
  );
  useEffect(() => {
    window.removeEventListener("keydown",myEventHandler);
    window.addEventListener("keydown",myEventHandler);
    return () => window.removeEventListener("keydown",myEventHandler);
  }, [document_list,change])

  useEffect(() => {
    localStorage.removeItem("draw-color")
  }, [])
  

  useEffect(() => {
    if (
      document.getElementsByTagName("canvas")[0]?.width
    ) {
      setSizeCanvas({
        width:document.getElementsByTagName("canvas")[0]?.width,
        height : document.getElementsByTagName("canvas")[0]?.height
      })

      if (document.getElementsByTagName("canvas")[0]?.width*document.getElementsByTagName("canvas")[0]?.height - 16777216 > 0) {
        changeScale(document_scale /1.2);
        setLimitZoomIn(true)
      } 
      getUserAddressBook(draw_info.user_address_book_id)
    }
  }, [document_scale,draw_info]);

  const onChangeDialog = () => {
    const status = !document_dialog;
    dispatch(document_set_dialog(status));
    if (!status) dispatch(document_set_info(null));
  };

  const onChangeSubDraw = async(id,info)=>{
    onChangeInfo(info)
  }

  const changeSubDrawInfo = async(draw_info)=>{
    const client = createClientDraw()
    await client.request(GET_SUB_DRAW, {draw_parent_id: draw_info.id}).then((data) => {
      let subData = data.getSubDraws
      if(subData.length > 0){
        subData.unshift(draw_info)
      } 
      dispatch(sub_draw_set_info(subData));
    })
  }

  const dialogComplain = useMemo(() => {
    const handleClose = () => {
      setDialogComplain(false);
      setComplainContent("");
    };
    return (
      <Dialog
        open={isDialogComplain}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{minWidth: '300px', minHeight: '50px', textAlign: 'center'}}>
          <DialogContentText id="alert-dialog-description">
            {complainContent}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
  }, [isDialogComplain, complainContent]);




  const changeScale = (stageScale) => {
    dispatch({
      type: KONVA_CHANGE,
      payload: {
        stageScale,
      },
    });
  };

  const onChangeInfo =async (info) => {
    if(change){
      await swalQuestion({
        html: 'この画像は編集中で、保存されていません。他の画像を開くと編集した内容を保存しますか？',
        confirmButtonText: '保存する',
        cancelButtonText: '保存しないで移動する',
      }).then(result => {
        if (result.isConfirmed) {
            dispatch(saveDrawDetailsNavigation(document_info.id, 'save'))
        }
        change = false;
      });
    }
    dispatch(document_set_info(info));
    dispatch(document_set_dialog(true));
    dispatch(initKonvaAction(info.elements ?? []));
  };

  const changeKonvaStep = (step) => {
    dispatch(changeStepKonvaAction(step));
  };

  const headerBackGroundColor = useMemo(() => {
    if (processing) return processing.color;
    return '#dff2ff';
  }, [document_info.process]);

  const CustomZoomInIcon = () => {
    const hasProcess =
    document_info.process === processConfirmed.type ||
    document_info.process === processAgreed.type;

  const hasRequest =
    document_info.process === processRequestConfirm.type ||
    document_info.process === processRequest.type;
    const condition = () => {
      if (!isMobile && !isTablet ) {
        return true
      } else {
        if (sizeCanvas.width*sizeCanvas.height - 16777216 < 0) {
          return true
        } else return false
      } 

    }

    return <Stack
sx={{
  width: '40px',
  height: '40px',
  justifyContent: 'center',
  alignItems: 'center',
  justifyContent: 'flex-end',
  cursor: 'pointer',
  border:
    hasProcess || hasRequest
      ? '2px solid rgba(255,255,255,0.7)'
      : '2px solid rgba(0,0,0,0.5)',
      opacity:condition()&&!limitZoomIn?1:0.2

}}
onClick={() => {

  if (condition()&&!limitZoomIn) {
  changeScale(document_scale * 1.2);

  }
}}
>
<Tooltip placement="top" arrow title="拡大">
  <ZoomInIcon
    className="icon_filter_default"
    fontSize="large"
    sx={{
      color: hasProcess || hasRequest ? '#fff' : '#000',
    }}
  />
</Tooltip>
</Stack>
  }

  const renderFooter = () => {
    const hasProcess =
      document_info.process === processConfirmed.type ||
      document_info.process === processAgreed.type;

    const hasRequest =
      document_info.process === processRequestConfirm.type ||
      document_info.process === processRequest.type;
    return (
      <div
        id='nav-bottom-guest'
        className={'w-100 position-absolute bottom-0'}
        // className={isTablet ? 'position-fixed' : 'w-100 position-absolute'}
        style={{
          bottom: 0,
          left: 0,
          // width: isTablet ? 'calc(100vw - 280px)' : '',
        }}
      >
        {/* <Stack
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end',
            my: 1,
            px: 1,
            position: 'relative',
          }}
          direction="row"
        >
          {document_info.to_confirmed && (
            <div
              className="text-right font-weight-normal"
              style={{ marginRight: '35px', marginBottom: '10px', color: headerBackGroundColor }}
            >
              {draw_info?.endUserName.length > 0 ? <>
                <span className="pr-3 font-weight-bold">
                  {draw_info?.endUserName} 様
                </span>
              </>:
              <span className="pr-3 font-weight-bold">
                  {endUser} 様
                </span>
                }
              <span className="pr-2">{processing.title}</span>
              {moment(new Date(Date.parse(document_info.processedAt))).format(
                'YYYY-MM-DD HH:mm:ss'
              ) ?? ''}
            </div>
          )}
        </Stack> */}
        <div
          className={
            'd-flex w-100 align-items-center px-4 justify-content-between'
          }
          style={{
            backgroundColor: headerBackGroundColor,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <div>
            <Stack direction="row" spacing={1}>
              {/* <Stack
                sx={{
                  width: '40px',
                  height: '40px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  cursor: 'pointer',
                  border:
                    hasProcess || hasRequest
                      ? '2px solid rgba(255,255,255,0.7)'
                      : '2px solid rgba(0,0,0,0.5)',
                }}
                onClick={() => {
                  changeScale(document_scale * 1.2);
                }}
              >
                <Tooltip placement="top" arrow title="拡大">
                  <ZoomInIcon
                    className="icon_filter_default"
                    fontSize="large"
                    sx={{
                      color: hasProcess || hasRequest ? '#fff' : '#000',
                    }}
                  />
                </Tooltip>
              </Stack> */}
              <CustomZoomInIcon/>

              <Stack
                sx={{
                  width: '40px',
                  height: '40px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  border:
                    hasProcess || hasRequest
                      ? '2px solid rgba(255,255,255,0.7)'
                      : '2px solid rgba(0,0,0,0.5)',
                }}
                onClick={() => {
                  changeScale(document_scale / 1.2);
        setLimitZoomIn(false)
                }}
              >
                <Tooltip placement="top" arrow title="縮小">
                  <ZoomOutIcon
                    className="icon_filter_default"
                    fontSize="large"
                    sx={{
                      color: hasProcess || hasRequest ? '#fff' : '#000',
                    }}
                  />
                </Tooltip>
              </Stack>
            </Stack>
          </div>
          <Pagination
            className="pagination-draw"
            count={sortDocumentByDate().length}
            color="primary"
            page={pageInfoIndex + 1}
            onChange={(event, page) => {
              dispatch(saveDocumentInfoCanvasAction(document_info.id));
              onChangeInfo(sortDocumentByDate()[page - 1]);
              changeSubDrawInfo(sortDocumentByDate()[page - 1])
            }}
            size="large"
          />
          {!hasProcess && <div style={{ width: 120 }}></div>}
          {hasProcess && (
            <div
              className="text-white text-center d-inline-flex align-items-center justify-content-center"
              style={{
                backgroundColor: processing.color,
                border: '2px solid white',
                width: 120,
              }}
            >
              {processing?.title}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderFileNavbar = ()=>{
    return (
      <>
      {
        Object.keys(sub_draw).length > 0 && 
        <Button variant='contained' className='f-btn-navbar f-btn-navbar-guest' 
          onClick={() => setShowNavbar(!isShowNavbar)}>
          サムネイル <i className={`ml-1 fa-solid fa-chevron-${isShowNavbar ? 'up' : 'down'}`}></i>
        </Button>
      }
      {
        isShowNavbar && Object.keys(sub_draw).length > 0 && 
        <div className='f-navbar f-navbar-guest'>
          <ul className='f-list'>
            
          { 
            Object.entries(sub_draw).map((drawItem,index)=>{
              const {url, url_resize, file_alias} = drawItem[1]?.attachment ?? {};
              return (<li key={drawItem[1].id} className={`f-item ${drawItem[1].id === document_info.id && "f-active"}`}>
                <img onClick={()=>onChangeSubDraw(document_info.id,drawItem[1])} src={url_resize ?? url} alt={file_alias}/>
              </li>)
            })
          }
          </ul>
        </div>
      }
      </>
    )
  }

  useEffect(() => {
    const bottomEl = document.getElementById('nav-bottom-guest');
    if(bottomEl){
      setfooterHeight(bottomEl.offsetHeight)
    }else{
      setfooterHeight(70)
    }
    if(isMobile){
      setheaderHeight(10)
    }
  }, [document_info,isMobile])
  

  return (
    document_info.id && (
      <div
        className="text-center w-100 h-100"
        id="document-content"
        onClick={(e) => {
          if (!popupStrokeRef?.current?.contains(e.target) && strokeDialog) {
            setStrokeDialog(false);
          }

          if (!popupColorRef?.current?.contains(e.target) && colorDialog) {
            setColorDialog(false);
          }
        }}
      >
        <div
          className={isMobile ? '' : 'document-dialog'}
          style={{
            position: 'relative',
            // height: '100vh',
            height: isMobile ? '' : '100%',
          }}
          open={document_dialog}
          onClose={() => {
            dispatch(saveDocumentInfoCanvasAction(document_info.id));
            onChangeDialog();
          }}
        >
          {!isMobile && (
            <DialogTitle
              className="d-flex"
              sx={{
                backgroundColor: headerBackGroundColor,
              }}
            >
              <div className="div-left">
                {!editable ? (
                  <div className={'icon icon-disable'}>
                    <Tooltip placement="top" title="線描画" arrow>
                      <ModeEditOutlineRounded />
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    className={'icon' + (pen_active ? ' icon-active' : '')}
                    onClick={() => {
                      console.log("pen_active",pen_active)
                      onChangeTool(pen_active ? null : TOOL.PEN);
                     
                    }}
                  >
                    <Tooltip placement="top" title="線描画" arrow>
                      <ModeEditOutlineRounded />
                    </Tooltip>
                  </div>
                )}

                {!editable ? (
                  <div className={'icon icon-disable'}>
                    <Tooltip placement="top" title="矢印" arrow>
                      <CallReceivedRounded />
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    className={'icon' + (arrow_active ? ' icon-active' : '')}
                    onClick={() => {
                      onChangeTool(arrow_active ? null : TOOL.ARROW);
                    }}
                  >
                    <Tooltip placement="top" title="矢印" arrow>
                      <CallReceivedRounded />
                    </Tooltip>
                  </div>
                )}
                {!editable ? (
                  <div className={'icon icon-disable'}>
                    <Tooltip placement="top" title="矢印" arrow>
                      <HorizontalRuleOutlinedIcon className='skew-icon' />
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    className={'icon' + (straight_line_active ? ' icon-active' : '')}
                    onClick={() => {
                      onChangeTool(straight_line_active ? null : TOOL.STRAIGHT_LINE);
                    }}
                  >
                    <Tooltip placement="top" title="直線" arrow>
                      <HorizontalRuleOutlinedIcon className='skew-icon' />
                    </Tooltip>
                  </div>
                )}
                {!editable ? (
                  <div className={'icon icon-disable'}>
                    <Tooltip placement="top" title="テキスト" arrow>
                      <span>あ</span>
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    className={'icon' + (text_active ? ' icon-active' : '')}
                    onClick={() => {
                      onChangeTool(text_active ? null : TOOL.TEXT);
                    }}
                  >
                    <Tooltip placement="top" title="テキスト" arrow>
                      <span>あ</span>
                    </Tooltip>
                  </div>
                )}

                {!editable ? (
                  <div className={'icon icon-disable'}>
                    <Tooltip placement="top" title="四角" arrow>
                      <CropDinOutlined />
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    className={'icon' + (square_active ? ' icon-active' : '')}
                    onClick={() => {
                      onChangeTool(square_active ? null : TOOL.SQUARE);
                    }}
                  >
                    <Tooltip placement="top" title="四角" arrow>
                      <CropDinOutlined />
                    </Tooltip>
                  </div>
                )}

                {!editable ? (
                  <div className={'icon icon-disable'}>
                    <Tooltip placement="top" title="丸" arrow>
                      <CircleOutlined />
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    className={'icon' + (circle_active ? ' icon-active' : '')}
                    onClick={() => {
                      onChangeTool(circle_active ? null : TOOL.CIRCLE);
                    }}
                  >
                    <Tooltip placement="top" title="丸" arrow>
                      <CircleOutlined />
                    </Tooltip>
                  </div>
                )}

                {!editable ? (
                  <div className={'icon icon-disable'}>
                    <Tooltip placement="top" arrow title="確認スタンプ">
                      <CheckRounded />
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    className={'icon' + (check_active ? ' icon-active' : '')}
                    onClick={() => {
                      onChangeTool(check_active ? null : TOOL.CHECK);
                    }}
                  >
                    <Tooltip placement="top" arrow title="確認スタンプ">
                      <CheckRounded />
                    </Tooltip>
                  </div>
                )}

                <div
                  className={'icon' + (drag_active ? ' icon-active' : '')}
                  onClick={() => {
                    onChangeTool(drag_active ? null : TOOL.DRAG);
                  }}
                >
                  <Tooltip placement="top" title="選択" arrow>
                    <NearMeIcon
                      style={{ WebkitTransform: 'scaleX(-1)' }}
                      className={
                        drag_active ? 'text-primary' : 'icon_filter_default'
                      }
                    />
                  </Tooltip>
                </div>

                <div
                  style={{
                    maxWidth: '25px',
                    height: '25px',
                  }}
                >
                  <div
                    className={'icon'}
                    style={{ maxWidth: '25px', backgroundColor: localStorage.getItem("draw-color") ? localStorage.getItem("draw-color") : color }}
                    onClick={() => {
                      setColorDialog(!colorDialog);
                    }}
                  ></div>
                  {colorDialog && (
                    <div className={'color-dialog'} ref={popupColorRef}>
                      <div className="close">
                        <span onClick={() => setColorDialog(!colorDialog)}>
                          ×
                        </span>
                      </div>
                      <p className="text-center">カラー選択</p>
                      <ul>
                        {COLOR_TAGS.map((color_tag, key) => {
                          return (
                            <li key={key}>
                              <span
                                style={{
                                  backgroundColor: color_tag,
                                  cursor: 'pointer',
                                  border:
                                    color_tag === 'transparent'
                                      ? '1px solid #000'
                                      : 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                onClick={() => {
                                  localStorage.setItem("draw-color", color_tag);
                                  onChangeValue('color', color_tag);
                                }}
                              >
                                {
                                  localStorage.getItem("draw-color") && localStorage.getItem("draw-color") === color_tag ? <CheckRounded
                                  className={
                                    color_tag === 'transparent'
                                      ? 'text-dark'
                                      : 'text-white'
                                  }
                                  fontSize="small"
                                  alt="icon checked"
                                /> :
                                <>
                                {
                                 !localStorage.getItem("draw-color") && color === color_tag && (
                                    <CheckRounded
                                      className={
                                        color_tag === 'transparent'
                                          ? 'text-dark'
                                          : 'text-white'
                                      }
                                      fontSize="small"
                                      alt="icon checked"
                                    />
                                  )
                                }
                                </>
                                }

                               
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>

                <Stack
                  sx={{
                    backgroundColor: 'white',
                    border: '1px solid black',
                    marginLeft: '5px',
                  }}
                >
                  <Input
                    type="number"
                    size="md"
                    defaultValue={16}
                    value={fontSize}
                    disabled={
                      document_info.process || document_info.from_confirmed
                        ? true
                        : false
                    }
                    slotProps={{
                      input: {
                        min: 1,
                        max: 100,
                        step: 1,
                      },
                    }}
                    onChange={(e) => {
                      if (e?.target?.value) {
                        onChangeValue('fontSize', e.target.value);
                      }
                    }}
                    sx={{
                      input: {
                        padding: 0,
                      },
                    }}
                  />
                </Stack>
                <div className="ms-2"></div>
                <div
                  className={'icon'}
                  onClick={() => {
                    changeKonvaStep(step ? step - 1 : 0);
                  }}
                >
                  <Tooltip placement="top" arrow title="元に戻す">
                    <UndoIcon />
                  </Tooltip>
                </div>
                <div
                  className={'icon'}
                  onClick={() => {
                    changeKonvaStep(
                      step < history.length - 1 ? step + 1 : step
                    );
                  }}
                >
                  <Tooltip placement="top" arrow title="やり直す">
                    <RedoIcon />
                  </Tooltip>
                </div>
              </div>

              <div className="div-right">
                {!editable ? (
                  <Stack
                    className={'icon icon-text icon-disable'}
                    style={{
                      fontSize: isTablet ? '11px' : '',
                    }}
                  >
                    {' '}
                    保存
                  </Stack>
                ) : (
                  <Stack
                    className={'icon icon-text'}
                    onClick={() => {
                      dispatch(
                        saveDrawDetailsByUserAction(document_info.id, 'save')
                      );
                      change = false
                      // onChangeTool();
                    }}
                    style={{
                      fontSize: isTablet ? '11px' : '',
                    }}
                  >
                    保存
                  </Stack>
                )}

                <div
                  className="icon"
                  onClick={() => {
                    dispatch(saveDocumentInfoCanvasAction(document_info.id));
                    onChangeDialog();
                  }}
                >
                  <CloseRounded />
                </div>
              </div>
            </DialogTitle>
          )}

          <DialogContent
            style={{
              color: color,
              padding: isMobile ? '0px' : '',
              // paddingRight: isTablet ? '20px' : '',
            }}
            className="m-0"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div className="d-flex flex-column align-items-center w-100">
                <div
                  ref={scrollRef}
                  className="show-scroll-bar"
                  style={{
                    maxHeight: isMobile ? '' : window.innerHeight - 120,
                    // overflowY: 'auto',
                    overflow: 'auto',
                    // height: isMobile ? '80vh' : isTablet ? '73vh' : '',
                    height: isMobile ? '78vh' : isTablet ? '73vh' : '',
                    width: isMobile ? '100vw' : '100%',
                    // border: isTablet ? '1px solid rgba(0,0,0,0.2)' : '',
                    border: '1px solid rgba(0,0,0,0.2)',
                    // backgroundColor: isTablet ? 'rgba(0,0,0,0.2)' : '',
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    // marginTop: isTablet ? '8px' : '',
                  }}
                >
                  <KonvaCanvas scrollRef={scrollRef} onChangeKonVa={handleSetKonvaChange} />
                </div>

                {isMobile ? (
                  <>
                    <Pagination
                      className="mt-2 mb-2 pagination-draw-mobile"
                      count={sortDocumentByDate().length}
                      variant="outlined"
                      color="primary"
                      page={pageInfoIndex + 1}
                      siblingCount={0}
                      onChange={(event, page) => {
                        dispatch(
                          saveDocumentInfoCanvasAction(document_info.id)
                        );
                        onChangeInfo(sortDocumentByDate()[page - 1]);
                        changeSubDrawInfo(sortDocumentByDate()[page - 1])
                      }}
                      size="medium"
                    />
                    {/* {document_info.to_confirmed ? (
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: 'flex-end',
                          width: '100%',
                          mr: 5,
                        }}
                      >
                        {`${processing.title} ` +
                          moment(
                            new Date(Date.parse(document_info.processedAt))
                          ).format('YYYY-MM-DD HH:mm:ss') ?? ''}
                      </Stack>
                    ) : (
                      <Stack
                        direction="row"
                        sx={{
                          height: '24px',
                        }}
                      />
                    )} */}
                  </>
                ) : null}
              </div>
            </div>
            {/* {document_info.to_confirmed && ( */}
            <div
              className="text-right font-weight-normal"
              style={{ marginRight: '35px', marginBottom: '10px', color: headerBackGroundColor,position:"absolute" , top:`${document_info.text_position === 'tl' || document_info.text_position === 'tr'?`${headerHeight}px`:'unset'}`,bottom:`${document_info.text_position === 'rb' || document_info.text_position === 'lb'?`${footerHeight}px`:'unset'}`,left:`${document_info.text_position === 'lb' || document_info.text_position === 'tl'?`8px`:'unset'}`,right:`${document_info.text_position === 'rb' || document_info.text_position === 'tr'?`8px`:'unset'}`}}
            >
              {
                !!document_info?.user_confirm_draws ?
                document_info?.user_confirm_draws?.map((item) => {
                  let isWarningRed = false;
                  const {to_confirmed, processedAt, user, user_address_book, complain, process} = item ?? {};
                  const userName = user_address_book?.user_name ?? user?.name;
                  if(process === processCancelRequest.type) isWarningRed = true;
                  if(process === processCancelRequestConfirm.type) isWarningRed = false;
                  const processing = findProcessDrawDetail(process);
                  if(draw_info.room.room_type && user.id !== me_draw.id) return null;
                  if(process === processAgreed.type || process === processConfirmed.type || process === processCancelRequest.type || process === processCancelRequestConfirm.type) {
                    return (
                      <div>
                        <span className="pr-3 font-weight-bold">
                          {userName} 様
                        </span>
                        <span className="pr-2">{processing?.title}</span>
                        {
                          (process === processCancelRequest.type || process === processCancelRequestConfirm.type) &&
                          <Tooltip title={"コメント内容"} placement="top">
                            <span className="pr-2 cursor-pointer" 
                              onClick={() => {setDialogComplain(!isDialogComplain); setComplainContent(complain)}}>
                              <img
                                src={isWarningRed ? IconWarningRed : IconWarningYellow}
                                alt="warning"
                                width={20}
                              />
                          </span>
                          </Tooltip>
                        }
                        {moment(new Date(Date.parse(processedAt))).format(
                          'YYYY-MM-DD HH:mm:ss'
                        ) ?? ''}
                      </div>
                    )
                  }
                })
                :
                <>
                  {draw_info?.endUserName.length > 0 ? <>
                    <span className="pr-3 font-weight-bold">
                      {draw_info?.endUserName} 様
                    </span>
                  </>:
                  <span className="pr-3 font-weight-bold">
                      {endUser} 様
                    </span>
                    }
                  <span className="pr-2">{processing?.title}</span>
                  {moment(new Date(Date.parse(document_info.processedAt))).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ) ?? ''}
                </>
              }
            </div>
          {/* )} */}

          </DialogContent>
          {!isMobile && renderFooter()}
        </div>
        {renderFileNavbar()}
        {dialogComplain}
      </div>
    )
  );
});

export class Document extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      openDownloadDialog: false,
      imgHover: {},
    };
    this.anchorElRef = React.createRef();
  }

  handleWindowResize = () => {
    this.setState({ ...this.state, width: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.props.getUserAddressBook()
  }

  showViewGrid(document_list) {
    const {
      onChangeRemove,
      onChangeInfo,
      document_info,
      me_draw,
      selectImage,
      selectedImageIds,
      onDeselectListItem,
      list,
      onChangeRemoveList,
      changeSubDrawInfo
    } = this.props;
    const { width } = this.state;
    const isMobile = width < WIDTH_MOBILE ? true : false;

    let html = [];
    for (const document of document_list) {
      const { date, images } = document;

      const sortedImages = sortDocuments(images, me_draw.id);

      html.push(
        <div className={date} key={date}>
          <div className="date">{date}</div>
          <ImageList
            cols={3}
            // sx={{ maxHeight: isMobile ? 195 : 372 }}
            rowHeight={90}
          >
            {sortedImages.map((image, key) => {
              const { id, file_same_name, file_same_name_have_ex, attachment, user_confirm_draws } = image;
              const {url, url_resize} = attachment ?? {};
              const urlImage = url_resize ?? url;
              const process =  user_confirm_draws?.find((item) => item?.user?.id === me_draw?.id)?.process;
              const processing = findProcessDrawDetail(process);
              let title = processing ? processing?.title : null;
              // if(user_confirm_draws?.find(({to_confirmed, process, user}) => to_confirmed && process === processConfirmed.type && user.id === me_draw.id)) {
              //   title = processConfirmed.title;
              // }
              // if(user_confirm_draws?.find(({to_confirmed, process, user}) => to_confirmed && process === processAgreed.type && user.id === me_draw.id)) {
              //   title = processAgreed.title;
              // }
              // if (image.from_confirmed && !image.to_confirmed && (process === processCancelRequest.type || process === processCancelRequestConfirm.type)) {
              //   title = '差戻し';
              // }

              return (
                <Tooltip placement="top" arrow title={this.state.imgHover?.id === image.id && file_same_name} key={key}>
                  <ImageListItem
                    className={`${document_info.id === id ? 'draw-active' : ''} ${document_info.parent_id && document_info.parent_id === id ? 'draw-active' : ''}`}
                    sx={{
                      cursor: 'pointer',
                    }}
                    onMouseOver={(e) => {
                      this.setState({ ...this.state, imgHover: image });
                    }}
                    onClick={async() => {
                     await onChangeInfo(document_info, image)
                      changeSubDrawInfo(image)
                      change = false
                    }}
                  >
                     {this.state.imgHover?.id === image.id && (
                      <div>
                        {!selectedImageIds.includes(image.id) && (
                          <input
                            type="checkbox"
                            style={{
                              position: 'absolute',
                              left: '2px',
                              cursor: 'pointer',
                              zIndex: 1,
                              height: '18px',
                              width: '18px',
                              // backgroundColor: '#0e3359',
                              // WebkitBoxShadow: '0 0 0px 1000px #102e50 inset',
                              outline: '1px solid #0e3359',
                              outlineOffset: '-1px',
                            }}
                            onChange={(e) => {
                              selectImage(image.id, e.target.checked);
                            }}
                            checked={selectedImageIds.includes(image.id)}
                          />
                        )}

                        <Stack
                          ref={this.anchorElRef}
                          aria-describedby={id}
                          type="button"
                          style={{
                            position: 'absolute',
                            right: '2px',
                            backgroundColor: '#0e3359',
                            cursor: 'pointer',
                            zIndex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(0,0,0,0.3)',
                            opacity: 0.6,
                            borderRadius: '2px',
                            height: '18px',
                            width: '18px',
                          }}
                          onClick={(e) => {
                            this.setState({
                              ...this.state,
                              anchorEl: this.anchorElRef.current,
                            });
                          }}
                        >
                          <MoreHorizIcon
                            className="color-white"
                            fontSize="small"
                          />
                        </Stack>
                        <RenderMenuPoper
                          open={!!this.state.anchorEl}
                          anchorEl={this.state.anchorEl}
                          handleClose={() => {
                            this.setState({
                              ...this.state,
                              anchorEl: null,
                            });
                          }}
                          fileName={this.state.imgHover?.file_same_name_have_ex}
                          handleDownload={() =>
                            this.setState({
                              ...this.state,
                              openDownloadDialog: true,
                              anchorEl: null,
                            })
                          }
                          handleDelete={() => onChangeRemove(image)}
                          disabledDelete={
                            !(!process && me_draw && me_draw.id === image.owner_id)
                          }
                          drawDetailId={document_info?.id}
                          setAnchorEl={() => this.setState({anchorEl: null})}
                        />
                      </div>
                    )} 
                    {selectedImageIds.includes(image.id) && (
                      <input
                        type="checkbox"
                        style={{
                          position: 'absolute',
                          left: '2px',
                          backgroundColor: '#0e3359',
                          cursor: 'pointer',
                          zIndex: 1,
                          height: '18px',
                          width: '18px',
                        }}
                        onChange={(e) => {
                          selectImage(image.id, e.target.checked);
                        }}
                        checked={selectedImageIds.includes(image.id)}
                      />
                    )}
                    <img
                      src={`${
                        urlImage +
                        (!urlImage ? "?'fit=crop&auto=format'" : '')
                      }`}
                      srcSet={`${
                        urlImage +
                        (!urlImage
                          ? "?'fit=crop&auto=format&dpr=2 2x'"
                          : '')
                      }`}
                      alt={image.title}
                      loading="lazy"
                    />
                    {process ? (
                      <span>
                        <ImageListItemBar
                          title={title}
                          position="top"
                          sx={{
                            background: processing.badge ? processing.badge : processing.color,
                            cursor: 'pointer',
                            border:
                              process === processAgreed.type ||
                              process === processConfirmed.type
                                ? '2px solid black'
                                : 'none',
                          }}
                          onClick={() => onChangeInfo(document_info, image)}
                        />
                        {(process === processAgreed.type ||
                          process === processConfirmed.type) &&
                          user_confirm_draws?.every(({to_confirmed}) => to_confirmed) && (
                          <CustomIconConfirm />
                        )}
                        {process === processCancelRequest.type && (
                          <CustomProcessCancel isWarningRed={true} />
                        )}
                         {process === processCancelRequestConfirm.type && (
                          <CustomProcessCancel isWarningRed={true} />
                        )}
                      </span>
                    ) : null}
                  </ImageListItem>
                </Tooltip>
              );
            })}
          </ImageList>
        </div>
      );
    }
    return (
      <div className="view-grid">
        {html}
        {document_list.length > 0 && (
          <>
            {selectedImageIds.length > 0 && (
              <Stack
                direction="row"
                py={1}
                // spacing={2}
                sx={{
                  backgroundColor: '#4ea3f8',
                  justifyContent: 'space-evenly',
                  borderRadius: '8px',
                  marginTop: '10px',
                }}
                onMouseOver={() => this.setState({...this.state, imgHover: {}})}
              >
                <Button
                  className="d-flex flex-column btn-secondary "
                  onClick={() => {
                    this.setState({ ...this.state, openDownloadDialog: true });
                  }}
                >
                  <FileDownloadOutlinedIcon />
                  <span className="button_invite_label">
                    {TEXT_LIST.download}
                  </span>
                </Button>

                <Button
                  disabled={isDeleteListItem(
                    selectedImageIds,
                    list,
                    me_draw
                  )}
                  className={`d-flex flex-column btn-secondary ${
                    isDeleteListItem(selectedImageIds, list, me_draw)
                      ? 'btn-disable'
                      : ''
                  }`}
                  onClick={onChangeRemoveList}
                >
                  <i className="fa-regular fa-trash-can" style={{
                      padding: '5px',
                      fontSize: '18px',
                    }}></i>
                  <span className="button_invite_label">削除</span>
                </Button>

                <Button
                  className="d-flex flex-column btn-secondary"
                  onClick={onDeselectListItem}
                >
                  <CloseRoundedIcon />
                  <span className="button_invite_label">選択解除</span>
                </Button>
              </Stack>
            )}
          </>
        )}
      </div>
    );
  }

  onPaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    let newFiles = []
    for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1 || items[i].type.indexOf('pdf') !== -1) {
            const imageFile = items[i].getAsFile();
            newFiles.push(imageFile)
            // this.processImage(imageFile);
        }
    }
    if(newFiles.length > 0){
      this.props.onDropFiles(newFiles)
    } 
  };

  render() {
    const {
      list,
      onDropFiles,
      downloadImages,
      handleSearchDrawList,
      property_sidebar,
    } = this.props;
    const document_list = [];

    const cg_tooltip_elm = document.getElementById("cg-tooltip-disable");
    for (const document of list) {
      const date = moment(document.date).format('YYYY/MM/DD');
      const index = document_list.findIndex((s) => s.date === date);
      if (index > -1) {
        document_list[index].images.push(document);
      } else {
        document_list.push({ date: date, images: [document] });
      }
    }

    
    return (
      <>
        <div className={`text-center p-2 overflow-scroll ${!cg_tooltip_elm ?'mh-72vh' :"" }`} id="documents"
          // ref={(ref) => {
          //   if (ref) {
          //       ref.addEventListener('paste', this.onPaste);
          //   }
          // }}
          style={{maxHeight:`${cg_tooltip_elm ? `calc(100vh - ${cg_tooltip_elm.offsetHeight + 54+45+45+48}px)`:'initial'}`}}
        >
          <UploadAndSearchDraw
            onDropFiles={(files) => onDropFiles(files)}
            handleSearchDrawList={handleSearchDrawList}
            isMultiple={true}
          />
          <div
            className={`dropzone-absolute ${
              !property_sidebar?.dragFile ? 'd-none' : ''
            }`}
          >
            <div className={`dropzone-wrapper d-inline-block`}>
              <div className="dropzone-item">
                <BackupOutlinedIcon className="icon-dropzone" />
                <p className="mb-1">ここにファイルを</p>
                <p className="mb-1">ドラッグアンドドロップ</p>
              </div>
            </div>
          </div>
          <div className="views">{this.showViewGrid(document_list)}</div>
        </div>
        <CommonModal
          open={this.state.openDownloadDialog}
          onClose={() => {
            this.setState({ ...this.state, openDownloadDialog: false });
          }}
          text={TEXT_LIST.download_images}
          handleCancel={() => {
            this.setState({ ...this.state, openDownloadDialog: false });
          }}
          handleConfirm={() => {
            downloadImages(this.state.imgHover?.id ? [this.state.imgHover?.id] : []);
            this.setState({ ...this.state, openDownloadDialog: false });
          }}
        />
      </>
    );
  }
}

export class DrawSideBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: 'document',
      isModalCombinePdf: false,
      isOpenIframeModal:false,
      isShowMobileMenu:true,
    };
  }

  handleChangeExpanded = (value) => {
    this.setState({ expanded: this.state.expanded === value ? '' : value });
  };


  render() {
    const {
      document_list,
      property_info,
      setOpenConfirmNotification,
      windowSize,
      document_draw,
      match,
      history,
      frame_list,
      me_draw,
      confirmList,
    } = this.props;
    const {isModalCombinePdf, width} = this.state;
    // const requestConfirmList = document_list.filter(
    //   (item) => item.from_confirmed && !item.to_confirmed && item?.process !== processCancelRequest.type && item?.process !== processCancelRequestConfirm.type
    // );
    const requestConfirmList = () => {
      // if (!!confirmList?.length) return confirmList;
      let result = document_list?.map((item) => {
        if(!!item?.user_confirm_draws?.length) {
          if(item.from_confirmed) {
            if(item?.user_confirm_draws?.find((t) => (t?.user?.id === me_draw?.id && !t?.to_confirmed && t?.process !== processCancelRequest.type && t?.process !== processCancelRequestConfirm.type))) {
              return item;
            }
          }
        } else {
          if(item.from_confirmed && !item.to_confirmed && item?.process !== processCancelRequest.type && item?.process !== processCancelRequestConfirm.type) {
            return item;
          }
        }
      })
      result = result?.filter((item) => item !== undefined)
      return result;
    };
    const userPrimary = document_draw?.room?.user_group_chats?.find((item) => item?.user?.id === me_draw?.id) ?? me_draw;
    const userName = userPrimary?.user_address_book?.user_name ?? userPrimary?.user?.name ?? userPrimary?.endUserName;
    // const isMobile = width < WIDTH_MOBILE ? true : false;
    const roomIsSelected = document_draw?.room_by_user?.find(({id}) => id === document_draw?.room_id);
    // const CG3DForm = () => {
    //   const url = window.location.href;
    //   return (
    //     <>
    //       {/* {
    //         !document_draw?.cg_property_id ? 
    //         <div className="cg-btn">
    //             <Typography className="">CG閲覧・編集</Typography>
    //         </div>
    //         :
    //         <a href={url +"/cg_property/" + document_draw?.cg_property_id}>
    //           <div className="cg-btn-active">
    //               <Typography className="">CG閲覧・編集</Typography>
    //           </div>
    //         </a> 
    //       } */}
    //     </>
    //   );
    // }

    return (
      <>
        {
        isMobile ?
          <>
            <MobileHeaderMenu
              history={history}
              draw_id={document_draw?.id}
              cg_id={document_draw?.cg_property_id}
              paramUrl={"guest"}
              frame_list={frame_list}
              onremoveItem={this.props.handleRemoveFrameItem}
              handleOpenCgModal={() => this.setState({isOpenIframeModal:true})}
              setIsShowModal={this.props.onCloseQuestion}
              showMenu={this.state.isShowMobileMenu}
              handleToggleMenu={()=> this.setState({isShowMobileMenu: !this.state.isShowMobileMenu})}
            />
            {
              this.state.isOpenIframeModal && <CG3DForm closeModal={()=>this.setState({isOpenIframeModal:false})} addNewFrame={this.props.handleAddNewFame}/>
            }
          </>
        :
          <div onTouchMove={(e) => e.stopPropagation()}>
            <Accordion sx={{ margin: 0 }}>
              <AccordionSummary className="ac-parent border-bottom-0 btn-color-main">
                <Typography className="ac-parent-title">
                  {property_info.name}
                </Typography>
                <div className='d-flex'>
                  <div className='mr-2 font-weight-bold'>{userName}</div>
                  <Tooltip placement="bottom" arrow title="チャット画面に移動します">
                    <div 
                      onClick={() =>{
                        const {id, property_id, property_job_type_id} = document_draw?.room ?? {};
                        if(!id) return alert("お部屋をお選びください")
                        const url = `${PCHAT_DOMAIN}/r/${property_id}/tab-id/${property_job_type_id}/general/${id}`
                        return window.open(url, "_blank")
                      }}
                    >
                      {document_draw?.room?.id && <i className="fa-regular fa-comment-dots cursor-pointer fs-18px"></i>}
                    </div>
                  </Tooltip>
                </div>
              </AccordionSummary>
            </Accordion>
            <div className='d-flex'>
            <div className='sidebar-left position-relative w-100'>
                {/* {
                  process.env.REACT_APP_CURR_ENV !== "production" && CG3DForm()
                } */}
                {
                this.state.isOpenIframeModal && <CG3DForm closeModal={()=>this.setState({isOpenIframeModal:false})} addNewFrame={this.props.handleAddNewFame}/>
                }
                <Accordion
                    expanded={this.state.expanded === 'schedule'}
                    sx={{ margin: 0 }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="ac-parent"
                      onClick={() => {
                        this.handleChangeExpanded('schedule');
                      }}
                    >
                      <Typography className="ac-parent-title">{isMobile ? 'やる事' : 'やる事・期限'}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Schedule draw_id={document_draw.id} room_id={document_draw.room_id} roleUser={me_draw?.id} />
                    </AccordionDetails>
                  </Accordion>
                <Accordion
                  sx={{
                    margin: 0,
                  }}
                  onClick={() => {
                    if (requestConfirmList().length) setOpenConfirmNotification(true);
                  }}
                  disabled={requestConfirmList().length > 0 ? false : true}
                >
                  <AccordionSummary className="ac-parent d-flex justify-content-center">
                    <div className="d-flex justify-content-center w-100">
                      <Badge
                        color="error"
                        variant={requestConfirmList().length ? 'dot' : 'standard'}
                      >
                        <Typography className="ac-parent-title">確認依頼</Typography>
                      </Badge>
                    </div>
                  </AccordionSummary>
                </Accordion>
                
                <Accordion
                  expanded={this.state.expanded === 'document'}
                  sx={{ margin: 0,  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="ac-parent"
                    onClick={() => this.handleChangeExpanded('document')}
                  >
                    <Typography className="ac-parent-title">ファイル</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CDocument />
                  </AccordionDetails>
                </Accordion>

                <div className='bottom-accordion-shape'>
                  <Button className='btn-under' onClick={() => this.setState({isModalCombinePdf: !isModalCombinePdf})}>PDFファイルダウンロード</Button>
                </div>
                <CombineImageIntoPdf
                  isModalCombinePdf={isModalCombinePdf}
                  setModalCombinePdf={() => this.setState({isModalCombinePdf: !isModalCombinePdf})}
                />
              </div>
                <div className='sidebar-right'>
                  <SidebarRight
                    history={history}
                    draw_id={document_draw?.id}
                    cg_id={document_draw?.cg_property_id}
                    paramUrl={"guest"}
                    frame_list={frame_list}
                    onremoveItem={this.props.handleRemoveFrameItem}
                    handleOpenCgModal={() => this.setState({isOpenIframeModal:true})}
                    setIsShowModal={this.props.onCloseQuestion}
                  />
                </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export const UserPreviewDialog = memo(({ list }) => {
  const processRequest = findProcessDrawDetail('request');
  const { me_draw, windowSize } = useSelector((a) => a.basic);

  const isMobile = useMediaQuery(`(max-width:${WIDTH_MOBILE}px)`);
  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h5"
        component="h2"
        fontWeight="700"
        textAlign="center"
        marginY={'12px'}
      >
        確認依頼
      </Typography>
      <div className="container">
        <Typography align="center">
          以下について確認依頼がきています。
        </Typography>
        <div
          className="mt-3"
          style={{
            height: isMobile ? 300 : 400,
            overflowY: 'auto',
          }}
        >
          <ImageList
            cols={isMobile ? 2 : 4}
            gap={15}
            sx={{ overflowY: 'visible' }}
          >
            {list
              .filter((document) => !!document.process)
              .map((image, index) => {
                const { base64, elements, user_confirm_draws } = image;
                const process =  user_confirm_draws?.find((item) => item?.user?.id === me_draw?.id)?.process;
                const processing = findProcessDrawDetail(process);

                return isMobile ? (
                  <ImageListItem
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={`${
                        image.base64 +
                        (!image.base64 ? "?'fit=crop&auto=format'" : '')
                      }`}
                      srcSet={`${
                        image.base64 +
                        (!image.base64
                          ? "?'fit=crop&auto=format&dpr=2 2x'"
                          : '')
                      }`}
                      alt={image.title}
                      loading="lazy"
                    />
                    {process ? (
                      <Stack
                        sx={{
                          background: processing.color,
                          border:
                            process === processAgreed.type ||
                            process === processConfirmed.type
                              ? '2px solid black'
                              : 'none',
                          textAlign: 'center',
                          position: 'absolute',
                          top: '10px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          width: '70%',
                          px: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '14px',
                            color: 'white',
                          }}
                        >
                          {processing?.title || null}
                        </Typography>
                      </Stack>
                    ) : null}
                  </ImageListItem>
                ) : (
                  <ImageListItem key={index}>
                    <ImagePreview src={base64} elements={elements} />
                    {process ? <ProcessLabel processing={processing} /> : null}
                  </ImageListItem>
                );
              })}
          </ImageList>
        </div>
      </div>
    </>
  );
});

// export const RenderMenuPoper = memo(
//   ({
//     anchorEl,
//     open,
//     handleClose,
//     fileName,
//     handleDownload,
//     handleDelete,
//     disabledDelete,
//   }) => {
//     return (
//       <Menu
//         autoFocus={false}
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         PaperProps={{
//           sx: {
//             backgroundColor: '#264362',
//             color: 'white',
//           },
//         }}
//         getcontentanchorel={null}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'center',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'center',
//         }}
//       >
//         {fileName && <MenuItem>{truncateText(fileName)}</MenuItem>}
//         <MenuItem onClick={handleDownload}>
//           <FileDownloadOutlinedIcon />
//           {TEXT_LIST.download}
//         </MenuItem>
//         {/* <MenuItem disabled={disabledDelete} onClick={handleDelete}>
//           <DeleteOutlineRoundedIcon />
//           消去
//         </MenuItem> */}
//       </Menu>
//     );
//   }
// );